import React, {FC, ReactElement} from 'react';
import {Anchor, Box, Text} from "grommet";
import {PageHeader} from "../components/PageHeader";
import {RoutedAnchor} from "../components/RoutedAnchor";

type Props = {
}

export const SupportPage: FC<Props> = (): ReactElement => {
    return (
        <Box flex={false} pad="medium">
            <PageHeader name='Инструкции за инсталация' />
            <Box gap='xsmall'>
                <Text>1. Монтирайте miBus на място с пряк изглед към рутера.</Text>
                <Text>2. Свържете сивия кабел към устройството, с което искате miBus да си комуникира. Жълтия кабел свържете към <strong>GND</strong>, кафевия към <strong>RS485A</strong>, а зеления към <strong>RS485B</strong>.</Text>
                <Text>3. Свържете USB кабела към зарядно за телефон (5V, минимум 0.5A).</Text>
                <Text>4. Изчакайте минута и отворете WIFI настройките на телефона си.</Text>
                <Text>5. Трябва да виждате мрежа с името <strong>ESPxxx</strong>. Свържете се с нея и отворете <Anchor target='_blank' href='http://192.168.4.1'>http://192.168.4.1</Anchor> в браузъра.</Text>
                <Text>6. Натиснете Configure WIFI, изберете мрежа и въведете публичния и частния ключ. Тях може да намерите <RoutedAnchor path='/profile'>в профила си</RoutedAnchor>.</Text>
                <Text>7. Изчакайте минута и отворете страницата с вашите <RoutedAnchor path='/devices'>устройства</RoutedAnchor>.</Text>
                <Text>8. Там трябва да виждате новото добавено устройство. Цъкнете на него и след това на гаечения ключ горе вдясно.</Text>
                <Text>9. Изберете марката и модела на устройството свързано с miBus, след което натиснете запази.</Text>
                <Text>10. След минута-две, miBus ще започне да изпраща информация, която може да разглеждате в реално време.</Text>
            </Box>
        </Box>
    );
};