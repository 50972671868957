import React, {FC, ReactElement} from "react";
import {Box, Button, Layer, Text} from "grommet";
import {FormClose, StatusWarning} from "grommet-icons";

interface Props {
    onClose: () => void,
    title: string,
    message: string
}

// TODO: Substitute with Grommet Notification once it is out https://v2.grommet.io/notification
export const Notification: FC<Props> = ({onClose, title, message}): ReactElement => {
    return (
        <Layer
            position="top"
            modal={false}
            margin={{ vertical: 'medium', horizontal: 'small' }}
            onEsc={onClose}
            responsive={false}
            plain
        >
            <Box
                direction="row"
                gap="small"
                align='start'
                justify="between"
                round="xsmall"
                elevation="medium"
                pad={{ vertical: 'xsmall', horizontal: 'small' }}
                background="white"
            >
                <Box justify='between' direction="row" gap="small" pad={{horizontal: 'medium', vertical: 'small'}}>
                    <StatusWarning color='status-warning' />
                    <Box>
                        <Text weight='bold'>
                            {title}
                        </Text>
                        <Text>{message}</Text>
                    </Box>
                </Box>
                <Button icon={<FormClose />} onClick={onClose} plain />
            </Box>
        </Layer>
    )
}