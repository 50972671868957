import React, { FC, ReactElement } from 'react';
import {Box, Button, Text} from "grommet";
import {CircleQuestion, Link, Unlink} from "grommet-icons";
import {useTranslation} from "react-i18next";

interface Props {
    activeConnection: boolean,
    status: string,
    size?: 'small' | 'medium' | 'large' | 'xlarge'
}

export const DeviceStatus: FC<Props> = ({activeConnection, status, size = 'medium'}): ReactElement => {
    const { t } = useTranslation();
    if (activeConnection) {
        return (
            <Box gap='xsmall' direction='row' align='center'>
                <Text weight='bold' size={size} truncate>{status || 'N/A'}</Text>
                <Link color='status-ok'/>
            </Box>
        )
    }
    return (
            <Box direction='row' gap='xsmall' align='center'>
                <Text weight='bold' size={size}>{t('No Communication')}</Text>
                <Unlink color='status-error'/>
                <Button icon={<CircleQuestion/>}
                        plain
                        tip={{
                            content: t('device_connection_inactive'),
                            dropProps: {
                                round: true,
                                background: {opacity: 'strong', color: 'background-contrast'},
                                align: {top: 'bottom', left: 'left'}}
                        }}/>
            </Box>

    );
};