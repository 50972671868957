import React, {FC, ReactElement, useMemo} from "react";
import {Box, Text} from "grommet";
import {isOnline as online} from "../../services/helpers";

interface Props {
    time: string
}

export const ConnectionStatus: FC<Props> = ({time}): ReactElement => {
    const isOnline = useMemo(() => online(time), [time]);
    return (
        <Box direction='row' gap='small' align='center'>
            <Box
                background={isOnline ? 'status-ok' : 'status-error'}
                width='14px'
                height='14px'
                round
            >
            </Box>
            <Text>{isOnline ? 'Online' : 'Offline'}</Text>
        </Box>
    );
}