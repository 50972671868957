import React, { FC, ReactElement } from 'react';
import {Box, Stack} from "grommet";

interface Props {
    color?: string
}

export const Logo: FC<Props> = ({color = 'white'}): ReactElement => (
    <Stack anchor="center">
        <Box
            width="xxsmall"
            height="xxsmall"
            round="small"
            align="center"
            justify="center"
            border={{ color: "accent-4", size: "xlarge" }}
        />
        <Box
            width={"32px"}
            height={"32px"}
            round="small"
            align="center"
            justify="center"
            background={color}
        />
    </Stack>
);