import React, {FC, ReactElement, useState} from 'react';
import {AggregatedStatsTile} from "../place/AggregatedStatsTile";
import {useTranslation} from "react-i18next";

interface Props {
    powerToday: number,
    powerMonth: number,
    pricePerKwh: number
}

export const RevenueWidget: FC<Props> = ({powerMonth, powerToday, pricePerKwh, ...rest}): ReactElement => {
    const {t} = useTranslation();
    const [option, setOption] = useState(powerToday);
    return (
        <AggregatedStatsTile
            {...rest}
            value={`${option ? (option * pricePerKwh).toFixed(2) : 'N/A'} ${t('leva')}`}
            options={[
                {plain: true, label: t('Today'), color: option === powerToday ? 'brand' : undefined, onClick: () => setOption(powerToday)},
                {plain: true, label: t('Last Month'), color: option === powerMonth ? 'brand' : undefined, onClick: () => setOption(powerMonth)}
            ]}
            title={t('Revenue')}
        />
    );
};