import React, {FC, ReactElement, useState} from 'react';
import {Box, Button, DateInput, Text} from "grommet";
import moment from "moment";
import {useTranslation} from "react-i18next";

interface Props {
    startDate: string,
    endDate: string,
    setStartDate: (arg: string) => void,
    setEndDate: (arg: string) => void,
}

const TODAY = 1;
const WEEK = 2;
const MONTH = 3;
const CUSTOM = 4;

export const DateFilter: FC<Props> = ({startDate, endDate, setStartDate, setEndDate}): ReactElement => {
    const { t } = useTranslation();
    const [active, setActive] = useState(TODAY);
    return (
        <Box gap='small'>
            <Box direction='row' border round='xsmall' alignSelf='end'>
                <Button hoverIndicator="light-1" onClick={() => {
                    setActive(TODAY);
                    setStartDate(moment().toISOString());
                    setEndDate(moment().toISOString());
                }} active={active === TODAY}>
                    <Box pad="small" direction="row" align="center" gap="small" border={{side: 'right'}} fill>
                        <Text>{t('Today')}</Text>
                    </Box>
                </Button>
                <Button hoverIndicator="light-1" onClick={() => {
                    setActive(WEEK);
                    setStartDate(moment().subtract(7, 'days').toISOString());
                    setEndDate(moment().toISOString());
                }} active={active === WEEK}>
                    <Box pad="small" direction="row" align="center" gap="small" border={{side: 'right'}} fill>
                        <Text>{t('Last Week')}</Text>
                    </Box>
                </Button>
                <Button hoverIndicator="light-1" onClick={() => {
                    setActive(MONTH);
                    setStartDate(moment().subtract(31, 'days').toISOString());
                    setEndDate(moment().toISOString());
                }} active={active === MONTH}>
                    <Box pad="small" direction="row" align="center" gap="small" border={{side: 'right'}} fill>
                        <Text>{t('Last Month')}</Text>
                    </Box>
                </Button>
                <Button hoverIndicator="light-1" onClick={() => {
                    setActive(CUSTOM);
                }} active={active === CUSTOM}>
                    <Box pad="small" direction="row" align="center" gap="small" fill>
                        <Text>{t('Custom Period')}</Text>
                    </Box>
                </Button>

            </Box>
            {active === CUSTOM && <DateInput format="dd/mm/yyyy-dd/mm/yyyy" value={[startDate, endDate]}
                                  onChange={(event: any) => {
                                      setStartDate(event.value[0]);
                                      setEndDate(event.value[1]);
                                  }}
            />}
        </Box>
    );
};