import React, {FC, ReactElement, useContext, useMemo} from "react";
import {Area, AreaChart, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import {Tile} from "../Tile";
import {timeFormat} from "d3-time-format";
import {Box, BoxProps, ResponsiveContext, Text} from "grommet";
import moment from "moment-timezone";
import {round} from "../../services/helpers";
import {useTranslation} from "react-i18next";
import {Time} from "grommet-icons";

const intervalModifier: { [key: string]: number } = {
    'xsmall': 4,
    'small': 4,
    'medium': 6,
    'large': 12,
    'xlarge': 0
}

const CustomTooltip: FC<any> = ({active, payload, label}) => {
    const {t} = useTranslation();
    if (active && payload && payload.length) {
        return (
            <Box background='light-1' pad='small' round='xsmall' elevation='xsmall'>
                <Text>{t('Time')}: {moment.utc(payload[0].payload.time).tz('Europe/Sofia').format('HH:mm')}</Text>
                <Text>{t('Power')}: {round(payload[0].payload.p_active)} kW</Text>
            </Box>
        );
    }

    return null;
};

interface Props extends BoxProps {
    startHour: string,
    endHour: string,
    areaProps?: any,
    data: {
        p_active: number,
        time: string
    }[]
}

export const PowerActivity: FC<Props> = ({startHour, endHour, data, areaProps = {}, ...rest}): ReactElement => {
    // TODO: Get timezone from assigned place
    const {t} = useTranslation();
    const size = useContext(ResponsiveContext);
    const interval = useMemo(() => Math.round(data.length / intervalModifier[size]), [data, size]);
    const formattedData = data.map(measure => ({
        ...measure,
        time: moment.utc(measure.time).tz('Europe/Sofia').valueOf()
    }));
    const timeFormatter = (tick: string) => timeFormat('%H:%M')(new Date(tick));

    return (
        <Tile title={t('Power Activity')} pad={{horizontal: 'small'}} {...rest}>
            {data.length > 0 ? <ResponsiveContainer width="100%" height={300}>
                    <AreaChart
                        width={500}
                        height={400}
                        // TODO: Fetch timezone from place
                        data={formattedData}
                        margin={{
                            top: 10,
                            right: 30,
                            left: 10,
                            bottom: 0,
                        }}
                    >
                        {/*<CartesianGrid strokeDasharray="3 3"/>*/}
                        {/*<XAxis dataKey="time" tickFormatter={timeStr => moment.utc(timeStr).tz('Europe/Sofia').format('HH:mm')} minTickGap={15} /> // Alternative which does not convert time to number*/}
                        <XAxis
                            dataKey="time"
                            scale='utc'
                            type='number'
                            domain={['auto', 'auto']}
                            tickFormatter={timeFormatter}
                            interval={interval}
                            allowDataOverflow={false}
                        />
                        <YAxis unit=' kW' domain={['auto', 'auto']}/>
                        <Tooltip content={<CustomTooltip/>}/>
                        <Area type="monotone" dataKey="p_active" stroke="#8884d8" fill="#8884d8" {...areaProps}/>
                    </AreaChart>
                </ResponsiveContainer> :
                <Box align='center' justify='center' direction='row' gap='small'>
                    <Time size='large' color='accent-4'/>
                    <Text size='xlarge'>{t('no_data')}</Text>
                </Box>
            }
        </Tile>
    );
}