import React from 'react';
import {Box, Grid} from "grommet";
import routes from "../routes";
import {Sidebar} from "./Sidebar/Sidebar";
import {ErrorBoundary} from "@sentry/react";
import {FatalError} from "../components/FatalError";


export const AuthorizedLayout = () => (
    <Grid
        fill
        rows={['auto']}
        columns={['auto', 'flex']}
        areas={[
            {name: 'sidebar', start: [0, 0], end: [0, 0]},
            {name: 'main', start: [1, 0], end: [1, 0]},
        ]}
    >
        <Sidebar gridArea="sidebar"/>
        <Box gridArea="main" overflow="auto" as="main">
            <ErrorBoundary fallback={(props) => <FatalError {...props} boxProps={{flex: false, fill: true}}/>}>
                {routes}
            </ErrorBoundary>
        </Box>
    </Grid>
);