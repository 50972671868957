import React, {FC, ReactElement, useContext, useMemo, useState} from 'react';
import {
    responsiveAreasCustomPeriod,
    responsiveColumnsCustomPeriod,
    responsiveRowsCustomPeriod
} from "./layout";
import {ActivePower} from "../../components/device/ActivePower";
import {PowerActivity} from "../../components/widgets/PowerActivity";
import {OutputParameters} from "../../components/device/OutputParameters";
import {InputParameters} from "../../components/device/InputParameters";
import {MeterTile} from "../../components/device/MeterTile";
import {DigitReadingTile} from "../../components/device/DigitReadingTile";
import {Box, Grid, ResponsiveContext, Text} from "grommet";
import {gql, useQuery} from "@apollo/client";
import moment from "moment-timezone";
import {SpinnerContainer} from "../../components/SpinnerContainer";
import {Dot, DotProps} from "recharts";
import {round} from "../../services/helpers";
import {CircleInformation} from "grommet-icons";
import {useTranslation} from "react-i18next";

const FETCH_DEVICE_METRICS = gql`
    query($id: macaddr! $start: timestamp! $end: timestamp!) {
        historic_data: device_metrics_report_daily_avg(args: {device: $id, start_date: $start, end_date: $end}) {
            time: column_5min_time
            u_a
            u_b
            u_c
            i_a
            i_b
            i_c
            p_peak
            p_reactive
            p_daily
            p_active
            p_accum
            pf
            freq
            efficiency
            temp
            pv1_u
            pv1_i
            pv2_u
            pv2_i
            pv3_u
            pv3_i
            pv4_u
            pv4_i
            pv5_u
            pv5_i
            pv6_u
            pv6_i
            pv7_u
            pv7_i
            pv8_u
            pv8_i
        }
#        historic_data: device_metrics(where: {_and: [{device_id: {_eq: $id}}, {time: {_gt: $start}}, {time: {_lt: $end}}]} order_by: {time: asc}) {
#            time
#            p_active
#        }
    }
`;

const setHour = (dateISO: string, hour: number) => {
    const date = moment(dateISO).tz('Europe/Sofia');
    date.set({hours: hour, minutes: 0, seconds: 0});
    return date.utc().format();
}

interface Props {
    id: string,
    startDate: string,
    endDate: string
}

export const CustomPeriodStats: FC<Props> = ({id, startDate, endDate}): ReactElement => {
    const { t } = useTranslation();
    const size = useContext(ResponsiveContext);
    const [metrics, setMetrics] = useState<any>({});
    const startHour = useMemo(() => setHour(startDate, 6), [startDate]);
    const endHour = useMemo(() => setHour(endDate, 23), [endDate]);
    const {data, loading} = useQuery(FETCH_DEVICE_METRICS, {
        variables: {
            id,
            start: startHour,
            end: endHour
        }
    });
    // console.log('data', data);
    // console.log('metrics', metrics);
    if (loading) {
        return <SpinnerContainer/>
    }

    return (
        <React.Fragment>
            <Box gap='small' direction='row' align='center' margin={{top: 'medium'}}><CircleInformation color='status-ok' size='large'/><Text weight='bold'>{t('click_on_power_activity_dots')}</Text></Box>
            <Grid
                margin={{top: 'medium'}}
                align="center"
                gap="medium"
                // @ts-ignore
                rows={responsiveRowsCustomPeriod[size]}
                // @ts-ignore
                columns={responsiveColumnsCustomPeriod[size]}
                // @ts-ignore
                areas={responsiveAreasCustomPeriod[size]}
            >
                <PowerActivity startHour={startHour} endHour={endHour} data={data.historic_data.map((point: any) =>
                    ({...point, time: moment.utc(point.time, 'HH:mm:ss').format()})
                )}
                               areaProps={{
                                   activeDot: (props: DotProps) => {
                                       // console.log('as', a);
                                       // @ts-ignore
                                       return <Dot
                                           {...props}
                                           r={10}
                                           // @ts-ignore
                                           style={{cursor: 'pointer'}}
                                           onClick={(event: any, data) => setMetrics(event.payload)}
                                       />;
                                   }
                               }}
                               gridArea='powerActivity'/>
                {Object.keys(metrics).length > 0 ? <React.Fragment>
                    <Box gridArea='selectedTime' direction='row' gap='xsmall'><Text size='large'>{t('averaged_results_for_time')}</Text><Text weight='bold' size='large'>{moment(metrics.time).format('HH:mm')}</Text></Box>
                    <ActivePower gridArea='activePower' data={metrics}/>
                    <OutputParameters gridArea='outputParameters' data={metrics}/>
                    <InputParameters gridArea='inputParameters' data={metrics}/>
                    <MeterTile gridArea='efficiency' value={metrics.efficiency || 0} title={t('Efficiency')}/>
                    <MeterTile gridArea='powerFactor' value={(metrics.pf || 0) * 100} title={t('Power Factor')}/>
                    <DigitReadingTile gridArea='powerPeak' value={round(metrics.p_peak)} title={t('Power Peak')} unit='kW'/>
                    <DigitReadingTile gridArea='accumulatedPower' value={round(metrics.p_daily)} title={t('Accumulated Power')}
                                      unit='kW'/>
                    <DigitReadingTile gridArea='cabinetTemperature' value={round(metrics.temp)} title={t('Cabinet Temperature')}
                                      unit='°C'/>
                </React.Fragment> : null}
            </Grid>
        </React.Fragment>
    );
};