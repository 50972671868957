import React, {FC, ReactElement, useMemo} from "react";
import {Tile, TileProps} from "../Tile";
import {Box, Stack, Text, Meter} from "grommet";
import {round} from "../../services/helpers";
import {useTranslation} from "react-i18next";

const colors = ['brand', 'accent-1', 'accent-2', 'accent-3', 'accent-4', 'neutral-1', 'neutral-2', 'neutral-3'];

interface LegendItemProps {
    showValue?: boolean,
    item: ActivePowerItem
}

const LegendItem: FC<LegendItemProps> = ({ item, showValue = true }) => (
    <Box direction="row" gap="xsmall" align="center">
        <Box background={item.color} pad="xsmall" margin="xxsmall" />
        <Text size="xsmall" tip={item.tip ? {
            content: item.tip,
            dropProps: {
                round: true,
                background: {opacity: 'strong', color: 'background-contrast'},
                align: {bottom: 'top', left: 'left'}
            }
        } : undefined}>
            {item.label}
        </Text>
        {showValue && (
            <Text weight="bold" size="xsmall">
                {round(item.power)} kW
            </Text>
        )}
    </Box>
);

interface LegendProps {
    items: ActivePowerItem[]
}

const Legend: FC<LegendProps> = ({ items, ...rest }) => (
    <Box pad="small" gap="medium" direction="row" align="center" wrap>
        {items.map((item: ActivePowerItem) => <LegendItem key={item.label} item={item} {...rest}/>)}
    </Box>
);

export interface ActivePowerItem  {
    color?: string;
    highlight?: boolean;
    label: string;
    onClick?: (event: React.MouseEvent) => void;
    onHover?: (over: boolean) => void;
    value: number;
    tip?: string;
    power: number;
}

interface Props extends TileProps{
    power: number,
    values: ActivePowerItem[]
}

export const ActivePowerWidget: FC<Props> = ({power, values, ...rest}): ReactElement => {
    const { t } = useTranslation();
    const items = useMemo(() =>
        values?.map((value, index) =>
            ({...value, color: colors[index % colors.length]})),
        [values]);

    return (
        <Tile {...rest} title={t('Active Power')}>
            <Box flex margin="medium" align="center">
                <Stack anchor="center" guidingChild="last" alignSelf="center">
                    <Box direction="row" align="center" pad={{ bottom: 'xsmall' }} gap='xsmall'>
                        <Text size="xlarge" weight="bold">
                            {round(power)}
                        </Text>
                        <Text size="small">kW</Text>
                    </Box>
                    <Meter
                        size='small'
                        thickness='medium'
                        type="circle"
                        values={items}
                    />
                </Stack>
            </Box>
            <Legend items={items} />
        </Tile>
    )
}