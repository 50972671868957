export const responsiveRows = {
    small: ['medium', 'medium', 'small', '580px', 'xsmall', 'small', 'xsmall'],
    medium: ['medium', 'small', 'medium', 'small'],
    large: ['medium', 'small', 'small'],
};

export const responsiveColumns = {
    small: ['flex', 'flex'],
    medium: ['flex', 'flex', 'flex'],
    large: ['flex', 'flex', 'flex', 'flex', 'flex', 'flex', 'flex'],
};

export const responsiveAreas = {
    small: [
        { name: 'activePower', start: [0, 0], end: [1, 0] },
        { name: 'powerActivity', start: [0, 1], end: [1, 1] },
        { name: 'outputParameters', start: [0, 2], end: [1, 2] },
        { name: 'inputParameters', start: [0, 3], end: [1, 3] },
        { name: 'powerPeak', start: [0, 4], end: [0, 4] },
        { name: 'accumulatedPower', start: [1, 4], end: [1, 4] },
        { name: 'efficiency', start: [0, 5], end: [0, 5] },
        { name: 'powerFactor', start: [1, 5], end: [1, 5] },
        { name: 'cabinetTemperature', start: [0, 6], end: [1, 6] },
    ],
    medium: [
        { name: 'activePower', start: [0, 0], end: [0, 0] },
        { name: 'powerActivity', start: [1, 0], end: [2, 0] },
        { name: 'outputParameters', start: [0, 1], end: [0, 1] },
        { name: 'efficiency', start: [1, 1], end: [1, 1] },
        { name: 'powerFactor', start: [2, 1], end: [2, 1] },
        { name: 'inputParameters', start: [0, 2], end: [2, 2] },
        { name: 'powerPeak', start: [0, 3], end: [0, 3] },
        { name: 'accumulatedPower', start: [1, 3], end: [1, 3] },
        { name: 'cabinetTemperature', start: [2, 3], end: [2, 3] },
    ],
    large: [
        { name: 'activePower', start: [0, 0], end: [2, 0] },
        { name: 'powerActivity', start: [2, 0], end: [6, 0] },
        { name: 'outputParameters', start: [0, 1], end: [1, 1] },
        { name: 'efficiency', start: [2, 1], end: [2, 1] },
        { name: 'powerFactor', start: [3, 1], end: [3, 1] },
        { name: 'powerPeak', start: [0, 2], end: [0, 2] },
        { name: 'accumulatedPower', start: [1, 2], end: [1, 2] },
        { name: 'cabinetTemperature', start: [2, 2], end: [2, 2] },
        { name: 'inputParameters', start: [4, 1], end: [6, 2] },
    ],
};


export const responsiveRowsCustomPeriod = {
    small: ['medium', 'xsmall', 'medium', 'small', '580px', 'xsmall', 'small', 'xsmall'],
    medium: ['medium', 'xsmall', 'small', 'small', 'small', 'small', 'small'],
    large: ['medium', 'xsmall', 'small', 'small', 'small'],
};

export const responsiveColumnsCustomPeriod = {
    small: ['flex', 'flex'],
    medium: ['flex', 'flex', 'flex'],
    large: ['flex', 'flex', 'flex', 'flex', 'flex', 'flex', 'flex'],
};

export const responsiveAreasCustomPeriod = {
    small: [
        { name: 'powerActivity', start: [0, 0], end: [1, 0] },
        { name: 'selectedTime', start: [0, 1], end: [1, 1]},
        { name: 'activePower', start: [0, 2], end: [1, 2] },
        { name: 'outputParameters', start: [0, 3], end: [1, 3] },
        { name: 'inputParameters', start: [0, 4], end: [1, 4] },
        { name: 'powerPeak', start: [0, 5], end: [0, 5] },
        { name: 'accumulatedPower', start: [1, 5], end: [1, 5] },
        { name: 'efficiency', start: [0, 6], end: [0, 6] },
        { name: 'powerFactor', start: [1, 6], end: [1, 6] },
        { name: 'cabinetTemperature', start: [0, 7], end: [1, 7] },
    ],
    medium: [
        { name: 'powerActivity', start: [0, 0], end: [2, 0] },
        { name: 'selectedTime', start: [0, 1], end: [2, 1]},
        { name: 'activePower', start: [0, 2], end: [0, 3] },
        { name: 'outputParameters', start: [0, 4], end: [1, 4] },
        { name: 'efficiency', start: [2, 4], end: [2, 4] },
        { name: 'powerFactor', start: [0, 5], end: [0, 5] },
        { name: 'inputParameters', start: [1, 2], end: [2, 3] },
        { name: 'powerPeak', start: [1, 5], end: [1, 5] },
        { name: 'accumulatedPower', start: [2, 5], end: [2, 5] },
        { name: 'cabinetTemperature', start: [0, 6], end: [0, 6] },
    ],
    large: [
        { name: 'powerActivity', start: [0, 0], end: [6, 0] },
        { name: 'selectedTime', start: [0, 1], end: [6, 1]},
        { name: 'activePower', start: [0, 2], end: [1, 3] },
        { name: 'outputParameters', start: [2, 2], end: [3, 2] },
        { name: 'efficiency', start: [2, 3], end: [2, 3] },
        { name: 'powerFactor', start: [3, 3], end: [3, 3] },
        { name: 'powerPeak', start: [2, 4], end: [2, 4] },
        { name: 'accumulatedPower', start: [1, 4], end: [1, 4] },
        { name: 'cabinetTemperature', start: [0, 4], end: [0, 4] },
        { name: 'inputParameters', start: [4, 2], end: [6, 3] },
    ],
};