import React, {FC, ReactElement} from 'react';

import {Box, BoxProps, Button, Heading} from 'grommet';
import { More } from 'grommet-icons';
import {SpinnerContainer} from "./SpinnerContainer";

export interface TileProps extends BoxProps{
    title: string,
    loading?: boolean
}

export const Tile: FC<TileProps> = ({ children, title, loading, ...rest }): ReactElement => (
    <Box elevation="medium" fill="vertical" round="small" {...rest}>
        <Box
            tag="header"
            pad={{ horizontal: 'small', top: 'small', bottom: 'medium' }}
            direction="row"
            justify="between"
            align="center"
        >
            <Heading level={3} size="xsmall" margin="none" truncate>
                {title}
            </Heading>
            <Button icon={<More color="control" />} />
        </Box>
        {loading ? <SpinnerContainer size='medium'/> : children}
    </Box>
);