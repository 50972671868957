import React, {FC, ReactElement} from 'react';
import {Box, Text} from "grommet";
import {AuthPageWrapper} from "./components/AuthPageWrapper";
import {MailOption} from "grommet-icons";
import {RoutedAnchor} from "../../components/RoutedAnchor";
import {useTranslation} from "react-i18next";

export const SuccessfulSignUpPage: FC = (): ReactElement => {
    const { t } = useTranslation();
    return (
        <AuthPageWrapper withNav={false}>
            <Box pad='medium' gap='small' align='center'>
                <Text alignSelf='center'>{t('account_created_successfully')}</Text>
                <MailOption color='brand' size='large'/>
                <Text alignSelf='center'>{t('verify_email_sent')}</Text>
                <RoutedAnchor path='/sign-in' label={t('Back to sign in')}/>
            </Box>
        </AuthPageWrapper>
    );
};