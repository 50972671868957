import axios from "axios";

const URL = 'https://nominatim.openstreetmap.org/search';

const API = axios.create({
    timeout: 3000,
    headers: {'Content-Type': 'application/json'}
});

interface NominatimAddress {
    village?: string,
    city?: string,
    postcode?: string,
    municipality: string,
    country: string,
    county: string,
    country_code: string
}

export interface NominatimPlace {
    place_id: number,
    osm_type: string,
    osm_id: number,
    boundingbox: string[],
    lat: string,
    lon: string,
    display_name: string,
    address: NominatimAddress
}

export const locationSearch = async (phrase: string) => {
    const result = await API.get(URL, {
        params: {
            q: phrase,
            format: 'json',
            addressdetails: 1,
            countrycodes: 'bg',
            limit: 3
        }
    })
    return result.data
}