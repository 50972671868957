import React, {FC, ReactElement} from 'react';
import {Box, BoxProps, Table, TableBody, TableCell, TableHeader, TableRow} from 'grommet';
import {Tile} from '../Tile';
import {round} from "../../services/helpers";
import {useTranslation} from "react-i18next";

interface Props extends BoxProps{
    data: any
    // data: {
    //     pv1_u: number,
    //     pv1_i: number,
    //     pv2_u: number,
    //     pv2_i: number,
    //     pv3_u: number,
    //     pv3_i: number,
    //     pv4_u: number,
    //     pv4_i: number,
    //     pv5_u: number,
    //     pv5_i: number,
    //     pv6_u: number,
    //     pv6_i: number,
    //     pv7_u: number,
    //     pv7_i: number,
    //     pv8_u: number,
    //     pv8_i: number,
    // },
}

const PV_COUNT = 8;

export const InputParameters: FC<Props> = ({data, ...rest}): ReactElement => {
    const { t } = useTranslation();
    return (
        <Tile title={t('Input Parameters')} {...rest}>
            <Box flex/>
            <Table>
                <TableHeader>
                    <TableRow>
                        <TableCell scope="col" border="bottom">
                            <strong>{t('String')}</strong>
                        </TableCell>
                        <TableCell scope="col" border="bottom">
                            <strong>{t('Voltage')}</strong>
                        </TableCell>
                        <TableCell scope="col" border="bottom">
                            <strong>{t('Current')}</strong>
                        </TableCell>
                        <TableCell scope="col" border="bottom">
                            <strong>{t('Power')}</strong>
                        </TableCell>
                    </TableRow>
                </TableHeader>
                <TableBody>
                    {[...Array(PV_COUNT)].map((_, i) => (<TableRow key={i + 1}>
                        <TableCell scope="row">
                            <strong>{i + 1}</strong>
                        </TableCell>
                        <TableCell scope="row">
                            {round(data[`pv${i + 1}_u`])} V
                        </TableCell>
                        <TableCell>{round(data[`pv${i + 1}_i`])} A</TableCell>
                        <TableCell>
                            {round(data[`pv${i + 1}_i`] * data[`pv${i + 1}_u`] / 1000)} kW
                        </TableCell>
                    </TableRow>))}
                </TableBody>
            </Table>
        </Tile>
    );
};