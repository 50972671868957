import React, {FC, ReactElement, useContext, useMemo} from 'react';
import {useTranslation} from "react-i18next";
import {Box, ResponsiveContext, Text} from "grommet";
import moment from "moment-timezone";
import {timeFormat} from "d3-time-format";
import {Tile} from "../Tile";
import {Bar, BarChart, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import {Time} from "grommet-icons";
import {round} from "../../services/helpers";

const intervalModifier: { [key: string]: number } = {
    'xsmall': 2,
    'small': 2,
    'medium': 6,
    'large': 100,
    'xlarge': 100
}

const CustomTooltip: FC<any> = ({active, payload, label}) => {
    const {t} = useTranslation();
    if (active && payload && payload.length) {
        return (
            <Box background='light-1' pad='small' round='xsmall' elevation='xsmall'>
                <Text>{t('Time')}: {moment.utc(payload[0].payload.time).tz('Europe/Sofia').format('ddd, MMM D')}</Text>
                <Text>{t('Power')}: {round(payload[0].payload.p_daily)} kW</Text>
            </Box>
        );
    }

    return null;
};

interface Props {
    startHour: string,
    endHour: string,
    areaProps?: any,
    data: {
        p_daily: number,
        time: string
    }[],
}

export const PowerProducedDaily: FC<any> = ({startHour, endHour, data, areaProps = {}, ...rest}): ReactElement => {
    // TODO: Get timezone from assigned place
    const {t} = useTranslation();
    const size = useContext(ResponsiveContext);
    const interval = useMemo(() => Math.round(data.length / intervalModifier[size]), [data, size]);
    const formattedData = data.map((measure:any) => ({
        ...measure,
        time: moment.utc(measure.time).tz('Europe/Sofia').valueOf()
    }));
    const timeFormatter = (tick: string) => timeFormat('%A')(new Date(tick));
    const xAxisPad = useMemo(() => size === 'large' || size === 'xlarge' ? 80 : size === 'medium' ? 30 : 50, [size]);
    return (
        <Tile title={t('Power Produced for the Week')} pad={{horizontal: 'small'}} {...rest}>
            {data.length > 0 ? <ResponsiveContainer width="100%" height={size === 'small' ? 300 : 350}>
                    <BarChart
                        width={500}
                        height={300}
                        data={formattedData}
                        margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 5,
                        }}
                    >
                        <XAxis
                            padding={{left: xAxisPad, right: xAxisPad}}
                            dataKey="time"
                            scale='utc'
                            type='number'
                            domain={['auto', 'auto']}
                            tickFormatter={timeFormatter}
                            interval={interval}
                            allowDataOverflow={false}
                        />
                        <YAxis unit=' kW' domain={['auto', 'auto']} />
                        <Tooltip content={<CustomTooltip/>}/>
                        <Bar dataKey="p_daily" fill="#82ca9d" />
                    </BarChart>
                </ResponsiveContainer> :
                <Box align='center' justify='center' direction='row' gap='small'>
                    <Time size='large' color='accent-4'/>
                    <Text size='xlarge'>{t('no_data')}</Text>
                </Box>
            }
        </Tile>
    );
};