import axios from 'axios';
import {AUTH_TOKEN_KEY, loadState} from '../localStorage';

export const AUTH_URL = process.env.REACT_APP_AUTH_API_URL;

const API = axios.create({
    timeout: 3000,
    headers: {'Content-Type': 'application/json'}
});

/*
    The authorization header is added only if the request is to our API to prevent sending credential to third parties.
    This is run on each request so if the token changes (for example as a result of a token refresh) then the next
    request picks up the new token. Also a check for existing authorization value in the request is done to allow
    overriding of the header from the call site if that happened to be necessary for whatever reason.

    Reference: https://github.com/axios/axios/issues/1383#issuecomment-377870814
*/
API.interceptors.request.use(
    config => {
        if (!config.headers.Authorization) {
            const token = loadState(AUTH_TOKEN_KEY);
            if (token) config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    error => Promise.reject(error)
);

export default API;