import React, {FC, ReactElement, useState} from "react";
import {Notification} from "../Notification";

interface Props {
    show: boolean
}

export const NoApiKeyNotification: FC<Props> = ({show}): ReactElement | null => {
    const [visible, setVisible] = useState<boolean>(show);
    if (!visible) {
        return null;
    }

    return (
        <Notification onClose={() => setVisible(false)} title="You don't have an API Key!" message='You need to generate an API key-pair from your profile page in order to attach devices to your account.'/>
        // <Notification
        //     toast
        //     status='warning'
        //     title='You don't have an API Key!'
        //     message='You need to generate an API key-pair in order to attach devices to your account.'
        //     // @ts-ignore
        //     onClose{() => setVisible(false)}
        // />
    )
};