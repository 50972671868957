export const responsiveRows = {
    small:  ['auto', 'medium', 'medium', 'auto', 'medium', 'auto'],
    medium: ['small', 'medium', 'auto'],
    large:  ['small', 'medium', 'auto'],
};

export const responsiveColumns = {
    small: ['flex', 'flex'],
    medium: ['flex', 'flex', 'flex', 'flex'],
    large: ['flex', 'flex', 'flex', 'flex', 'flex', 'flex'],
};

export const responsiveAreas = {
    small: [
        { name: 'revenue', start: [0, 0], end: [0, 0] },
        { name: 'powerProduced', start: [1, 0], end: [1, 0] },
        { name: 'CO2', start: [0, 5], end: [0, 5] },
        { name: 'installedPower', start: [1, 5], end: [1, 5] },
        { name: 'activePower', start: [0, 1], end: [1, 1] },
        { name: 'powerActivity', start: [0, 2], end: [1, 2] },
        { name: 'weatherForecast', start: [0, 3], end: [1, 3] },
        { name: 'powerProducedDaily', start: [0, 4], end: [1, 4] },
    ],
    medium: [
        { name: 'revenue', start: [0, 0], end: [0, 0] },
        { name: 'powerProduced', start: [1, 0], end: [1, 0] },
        { name: 'CO2', start: [2, 0], end: [2, 0] },
        { name: 'installedPower', start: [3, 0], end: [3, 0] },
        { name: 'activePower', start: [0, 1], end: [1, 1] },
        { name: 'powerActivity', start: [2, 1], end: [3, 1] },
        { name: 'weatherForecast', start: [0, 2], end: [1, 2] },
        { name: 'powerProducedDaily', start: [2, 2], end: [3, 2] },
    ],
    large: [
        { name: 'revenue', start: [0, 0], end: [0, 0] },
        { name: 'powerProduced', start: [1, 0], end: [1, 0] },
        { name: 'CO2', start: [2, 0], end: [2, 0] },
        { name: 'installedPower', start: [3, 0], end: [3, 0] },
        { name: 'activePower', start: [0, 1], end: [1, 1] },
        { name: 'powerActivity', start: [2, 1], end: [5, 1] },
        { name: 'weatherForecast', start: [0, 2], end: [2, 2] },
        { name: 'powerProducedDaily', start: [3, 2], end: [5, 2] },
    ],
};