import React, {FC, ReactElement, useContext, useMemo} from 'react';
import {Box, Grid, ResponsiveContext, Text, Stack, Button, Heading} from "grommet";
import {gql, useQuery} from "@apollo/client";
import {PageHeader} from "../../components/PageHeader";
import {RouteComponentProps, useHistory} from "react-router-dom";
import moment from "moment-timezone";
import {SignalStrengthMeter} from "../../components/device/SignalStrengthMeter";
import {ConnectionStatus} from "../../components/device/ConnectionStatus";
import {SpinnerContainer} from "../../components/SpinnerContainer";
import {Configure, Group} from "grommet-icons";
import {NotConfiguredNotification} from "../../components/device/NotConfiguredNotification";
import {DateFilter} from "../../components/device/DateFilter";
import {TodayStats} from "./TodayStats";
import {CustomPeriodStats} from "./CustomPeriodStats";
import {isOnline} from "../../services/helpers";
import {useTranslation} from "react-i18next";
import {DeviceStatus} from "../../components/device/DeviceStatus";
import {useUserPermissions} from "../../components/device/userPermissions";

const FETCH_DEVICE_METRICS = gql`
    query($id: macaddr! $user_id: uuid!) {
        userId @client @export(as: "user_id")
        device: device_by_pk(id: $id) {
            id
            user_id
            user_devices(where: {_and: [{user_id: {_eq: $user_id}}, {device_id: {_eq: $id}}]}) {
                view
                edit
            }
            serial_number
            model_name
            signal_strength
            connection_active
            model_id
            status
            model {
                id
                name
                manufacturer {
                    id
                    name
                }
                model_type {
                    id
                    name
                }
            }
        }
        last_update: device_metrics(where: {device_id: {_eq: $id}} order_by: {time: desc} limit: 1) {
            time
        }
    }
`;

interface MatchParams {
    id: string;
}

export const DevicePage: FC<RouteComponentProps<MatchParams>> = ({match}): ReactElement => {
    const { t } = useTranslation();
    const size = useContext(ResponsiveContext);
    const [startDate, setStartDate] = React.useState(moment().toISOString());
    const [endDate, setEndDate] = React.useState(moment().toISOString());
    const today = useMemo(() => moment(startDate).isSame(moment(), 'day'), [startDate]);

    const {data, loading} = useQuery(FETCH_DEVICE_METRICS, {
        variables: {
            id: match.params.id,
        }
    });
    const {isOwner, canEdit} = useUserPermissions(data?.userId, data?.device);
    const online = useMemo(() => isOnline(data?.last_update[0]?.time), [data]);
    const history = useHistory();

    if (loading) {
        return <SpinnerContainer/>
    }

    return (
        <Box flex={false} pad="medium">
            <NotConfiguredNotification show={!data?.device?.model_id}/>
            <PageHeader
                name={`${t('Device')} ${match.params.id}`}
                heading={
                    <Box direction='row' gap='small' align='center'>
                        {!isOwner && <Group size='32px' color='dark-4'/>}
                        <Heading level={2} size="xsmall">{!isOwner && t('Shared')} {t('Device')} {match.params.id}</Heading>
                        {online && <SignalStrengthMeter size='large' strength={data?.device?.signal_strength}/>}
                    </Box>}
                action={canEdit && <Button icon={<Configure color='brand' size='30px'/>} plain onClick={() => history.push(`/devices/${match.params.id}/edit`)} />}
            />
            <Stack anchor='top-right'>
                <Box flex={false}>
                    <Grid
                        columns={size === 'small' ? ['auto'] : ['auto', 'auto']}
                        gap='small'
                        border={{side: 'bottom', color: 'light-4'}}
                        pad={{bottom: 'small'}}
                    >
                        <Box>
                            <Text>{t('Type')}: {data?.device?.model?.model_type?.name}</Text>
                            <Text>{t('Manufacturer')}: {data?.device?.model?.manufacturer?.name}</Text>
                            <Text>{t('Model')}: {data?.device?.model?.name}</Text>
                            <Text>{t('S/N')}: {data?.device?.serial_number || 'N/A'}</Text>
                            <Text>{t('Last update')}: {data?.last_update[0] ? moment.utc(data.last_update[0].time).tz('Europe/Sofia').fromNow() : 'N/A'}</Text>
                            <Box direction='row' gap='xsmall'>
                                <Text>{t('Status')}:</Text>
                                {online ? <DeviceStatus activeConnection={data?.device?.connection_active} status={data?.device?.status}/> : <Text>N/A</Text>}
                            </Box>
                        </Box>
                        <Box justify='end' align='end'>
                            <DateFilter startDate={startDate} endDate={endDate} setStartDate={setStartDate} setEndDate={setEndDate}/>
                        </Box>
                    </Grid>
                    {today ? <TodayStats id={match.params.id} date={startDate}/> : <CustomPeriodStats id={match.params.id} startDate={startDate} endDate={endDate}/>}
                </Box>
                <ConnectionStatus time={data?.last_update[0]?.time} />
            </Stack>
        </Box>
    );
};