import React, { FC, ReactElement } from 'react';
import {AuthPageWrapper} from "./components/AuthPageWrapper";
import {Box, Text} from "grommet";
import {RequestPasswordResetForm} from "./components/RequestPasswordResetForm";
import {RoutedAnchor} from "../../components/RoutedAnchor";
import {useTranslation} from "react-i18next";


export const RequestPasswordResetPage: FC = (): ReactElement => {
    const { t } = useTranslation();
    return (
        <AuthPageWrapper withNav={false}>
            <Box pad='medium' gap='small'>
                <Text alignSelf='center'>{t('reset_account_password')}</Text>
                <RequestPasswordResetForm/>
                <RoutedAnchor label={t('Sign in')} path='/sign-in' size='xsmall' alignSelf='center'/>
            </Box>
        </AuthPageWrapper>
    );
};