import React, {FC, ReactElement, useContext} from 'react';
import {Box, Button, ButtonExtendedProps, ResponsiveContext, Text} from "grommet";
import {Tile, TileProps} from "../Tile";

interface Props extends TileProps{
    icon?: ReactElement
    value: string | number,
    options: ButtonExtendedProps[]
}

const textSizeMap: {[key: string]: string} = {
    'xsmall': 'xxlarge',
    'small': 'xxlarge',
    'medium': 'xlarge',
    'large': 'xxlarge',
    'xlarge': 'xxlarge'

}

export const AggregatedStatsTile: FC<Props> = ({options, value, icon = null, ...rest}): ReactElement => {
    const size = useContext(ResponsiveContext);
    return (
        <Tile {...rest}>
            <Box direction='row' pad={{horizontal: 'medium', bottom: 'small'}} gap='medium' align='center' justify='center'>
                <Box gap={size === 'small' ? 'small' : 'xsmall'}>
                    <Text size={textSizeMap[size]} weight='bold'>{value}</Text>
                    <Box direction='row' gap='small' wrap>
                        {/* @ts-ignore */}
                        {options.map((option, index) => <Button key={index} {...option}/>)}
                    </Box>
                </Box>
            </Box>
        </Tile>
    );
};