import React, {FC, ReactElement, useCallback, useState} from "react";
import {Box, Button, Select, Text} from "grommet";
import {FormClose} from "grommet-icons";
import {gql, useQuery} from "@apollo/client";
import {SpinnerContainer} from "../SpinnerContainer";
import {useTranslation} from "react-i18next";

const FETCH_DEVICES = gql`
    query ($user_id: uuid!){
        userId @client @export(as: "user_id")
        device (where: {user_id: {_eq: $user_id}}){
            id
            place_id
            model {
                id
                name
                model_type {
                    id
                    name
                }
                manufacturer {
                    id
                    name
                }
            }
        }
    }
`;

const getLabel = (device: any) => {
    let label = device.id;
    if (device.model?.name) {
        label += ` (${device.model?.name})`
    } else if (device.model_name) {
        label += ` (${device.model_name})`
    }
    return label;
};

interface OptionProps {
    device: any
    onRemove: (arg: string) => void
}

const Option: FC<OptionProps> = ({device, onRemove}): ReactElement => (
    <Button
        key={`device_tag_${device.id}`}
        href="#"
        onClick={event => {
            event.preventDefault();
            event.stopPropagation();
            onRemove(device.id);
        }}
        onFocus={event => event.stopPropagation()}
    >
        <Box
            align="center"
            direction="row"
            gap="xsmall"
            pad={{vertical: 'xsmall', horizontal: 'small'}}
            margin="xsmall"
            background="brand"
            round="large"
        >
            <Text size="small">{getLabel(device)}</Text>
            <Box round="full" margin={{left: 'xsmall'}}>
                <FormClose size="small" style={{width: '12px', height: '12px'}}/>
            </Box>
        </Box>
    </Button>
);

interface Props {
    onSelect: (arg: string[]) => void
}

export const DeviceSelect: FC<Props> = ({onSelect = () => {}}): ReactElement => {
    const { t } = useTranslation();
    const [selected, setSelected] = useState<string[]>([]);
    const {data, loading} = useQuery(FETCH_DEVICES);

    const onRemove = useCallback((season: string) => {
        setSelected(selected =>
            selected.filter((selectedSeason: any) => selectedSeason !== season),
        );
    }, []);

    if (loading) {
        return <SpinnerContainer/>
    }

    return (
        <Select
            closeOnChange={false}
            options={data.device}
            labelKey={getLabel}
            valueKey={{key: 'id', reduce: true}}
            multiple
            value={selected}
            disabledKey='place_id'
            valueLabel={
                <Box wrap direction="row">
                    {selected && selected.length ? (
                        selected.map((selected) =>
                            <Option
                                key={selected}
                                device={data?.device?.find((device: any) => device.id === selected)}
                                onRemove={onRemove}
                            />
                        )
                    ) : (
                        <Box
                            pad={{vertical: 'xsmall', horizontal: 'small'}}
                            margin="xsmall"
                        >
                            <Text weight={600} style={{opacity: 0.4}}>{t('Select Devices')}</Text>
                        </Box>
                    )}
                </Box>
            }
            onChange={({value}) => {
                const values = [...value].sort();
                setSelected(values);
                onSelect(values);
            }}
        />
    )
}