import React, { FC, ReactElement } from 'react';
import {Box, ColumnConfig, DataTable, Spinner, Text} from "grommet";
import {gql, useQuery} from "@apollo/client";
import {PageHeader} from "../components/PageHeader";
import {useHistory} from "react-router-dom";
import {SignalStrengthMeter} from "../components/device/SignalStrengthMeter";
import {ConnectionStatus} from "../components/device/ConnectionStatus";
import {isOnline} from "../services/helpers";
import {useTranslation} from "react-i18next";
import {WifiLow} from "grommet-icons";

type Props = {
}

const FETCH_DEVICES = gql`
    query {
        device(order_by: [{updated_at: desc}, {id: asc}]) {
            id
            pv_string_count
            status_id
            model_name
            serial_number
            updated_at
            signal_strength
            device_metrics(order_by: {time: desc} limit: 1) {
                time
            }
            model {
                manufacturer {
                    id
                    name
                }
                model_type {
                    id
                    name
                }
                id
                name
            }
        }
    }
`;

const columns:ColumnConfig<any>[] = [
    { property: 'id', header: 'MAC Address' },
    { property: 'model.model_type.name', header: 'Type' },
    { property: 'serial_number', header: 'Serial Number' },
    { property: 'model.manufacturer.name', header: 'Manufacturer' },
    { property: 'model.name', header: 'Model' },
    {
        property: 'device_metrics',
        header: 'Status',
        render: ({device_metrics: dm}) => <ConnectionStatus time={dm[0]?.time}/>,
    },
    {
        property: 'signal_strength',
        header: 'Connection Strength',
        render: ({signal_strength: ss, device_metrics: dm}) => isOnline(dm[0]?.time) ? <SignalStrengthMeter strength={ss} size='large'/> : 'N/A',
    },
];


export const DevicesPage: FC<Props> = (): ReactElement => {
    const { t } = useTranslation();
    const {data, loading, error} = useQuery(FETCH_DEVICES);
    const history = useHistory();
    // console.log('data', data, loading, error);
    return (
        <Box flex={false} pad="medium">
            <PageHeader name={t('Devices')} />
            <Box flex={false}>
                <DataTable
                    // @ts-ignore
                    columns={columns.map(column => ({...column, header: t(column.header)}))}
                    data={data?.device}
                    primaryKey='id'
                    placeholder={loading ?
                        <Box fill align='center' pad='large'><Spinner/></Box> :
                        data?.device?.length > 0 ?
                            undefined :
                            <Box pad='small' align='start'><Text>{t('No devices')}.</Text></Box>
                    }
                    onClickRow={(event) => {
                        history.push(`/devices/${event.datum.id}`);
                        // console.log(event.datum);
                    }}
                />
            </Box>
        </Box>
    );
};