import React, {useEffect} from 'react';
import {Grommet} from "grommet";
import {UnauthorizedLayout} from "./layout/UnauthorizedLayout";
import {AuthorizedLayout} from "./layout/AuthorizedLayout";
import {useQuery} from "@apollo/client";
import {IS_LOGGED_IN} from "./services/auth";
import theme from "./config/theme";
import {Router} from "react-router";
import browserHistory from "./browserHistory";
import {setUser} from "@sentry/react";


function App() {
    const {data, client} = useQuery(IS_LOGGED_IN);
    // Hacky way to reset the store on logout
    useEffect(() => {
        !data.isLoggedIn && client.resetStore()
    }, [client, data]);

    useEffect(() => {
        data.userId && data.userEmail && setUser({id: data.userId, email: data.userEmail})
    }, [data]);

    return (
        <Router history={browserHistory}>
            <Grommet theme={theme} full>
                {data.isLoggedIn ? <AuthorizedLayout/> : <UnauthorizedLayout/>}
            </Grommet>
        </Router>
    );
}

export default App;
