import React, {FC, ReactElement, useCallback, useEffect, useState} from "react";
import {Box, Button, FormField, TextInput, Text} from "grommet";
import {PageHeader} from "../components/PageHeader";
import {useFormik} from "formik";
import {GeneralErrorField} from "./Auth/components/GeneralErrorField";
import * as Yup from "yup";
import {gql, useQuery} from "@apollo/client";
import {SpinnerContainer} from "../components/SpinnerContainer";
import {useAuth} from "../services/auth";
import API, {AUTH_URL} from "../config/api";
import {useTranslation} from "react-i18next";

const validationSchema = Yup.object().shape({
    email: Yup.string()
        .email('Invalid email')
        .required('Required'),
    name: Yup.string().required('Required'),
    password: Yup.string()
        .min(6, 'Too Short!')
        .max(32, 'Too Long!'),
    passwordRepeat: Yup.string()
        .when('password', {
            is: (password: string) => password && password.length > 0,
            then: Yup.string()
                .oneOf([Yup.ref('password'), null], "Passwords must match")
                .required('Required'),
            otherwise: Yup.string()
        })
});

interface FormFields {
    email: string,
    name: string,
    password: string,
    passwordRepeat: string,
}

const initialValues: FormFields = {
    email: '',
    name: '',
    password: '',
    passwordRepeat: ''
};

const FETCH_DATA = gql`
    query {
        user {
            id
            name
            email
            api_key
        }
    }
`;

export const ProfilePage: FC<any> = (): ReactElement => {
    const { t } = useTranslation();
    const [apiKey, setApiKey] = useState('');
    const [privateKey, setPrivateKey] = useState('');
    const {data, loading, refetch} = useQuery(FETCH_DATA, {onCompleted: data => setApiKey(data?.user[0]?.api_key)});
    const {updateProfile} = useAuth();
    const {errors, values, handleChange, handleSubmit, status} = useFormik({
        initialValues: {...initialValues, ...(data?.user[0] || {})},
        enableReinitialize: true,
        validationSchema,
        onSubmit: async (values, actions) => {
            await updateProfile(values, actions);
            await refetch();
        }
    });
    useEffect(() => {
        return () => {
            setPrivateKey('')
        };
    }, []);
    const generateApiKey = useCallback(async () => {
        try {
            const result = await API.post(AUTH_URL + 'generate-api-key');
            return result.data;
        } catch (error) {
            return null;
        }
    }, []);
    // const apiKey = useMemo(() => data?.user[0]?.api_key, [data]);
    console.log(data);
    if (loading) {
        return <SpinnerContainer/>
    }

    return (
        <Box flex={false} pad="medium" gap='medium'>
            <Box>
                <PageHeader name={t('Your Profile')}/>
                <form onSubmit={handleSubmit}>
                    <FormField label={t('Name')} error={errors.name}>
                        <TextInput
                            name="name"
                            type="text"
                            value={values.name || ""}
                            onChange={handleChange}
                        />
                    </FormField>
                    <FormField label="Email" error={errors.email}>
                        <TextInput
                            name="email"
                            type="email"
                            value={values.email || ""}
                            onChange={handleChange}
                        />
                    </FormField>
                    <FormField label={t('Password')} error={errors.password}>
                        <TextInput
                            name="password"
                            type="password"
                            value={values.password || ""}
                            onChange={handleChange}
                        />
                    </FormField>
                    <FormField label={t('Repeat Password')} error={errors.passwordRepeat}>
                        <TextInput
                            name="passwordRepeat"
                            type="password"
                            value={values.passwordRepeat || ""}
                            onChange={handleChange}
                        />
                    </FormField>
                    <GeneralErrorField status={status}/>
                    <Button
                        type='submit'
                        label={t('Save')}
                        primary
                        alignSelf='start'
                        hoverIndicator
                        margin={{top: 'small'}}
                        size='large'
                    />
                </form>
            </Box>
            <PageHeader name={t('Your API Key')}/>
            {apiKey ? <Box>
                <FormField label={t('Public Key')}>
                    <TextInput
                        // type="password"
                        disabled
                        value={apiKey || ""}
                    />
                </FormField>
                <FormField label={t('Private Key')}>
                    <TextInput
                        type={privateKey ? 'text' : 'password'}
                        disabled
                        value={privateKey || apiKey || ""}
                    />
                </FormField>
                {privateKey &&
                <Box gap='small'><Box direction='row' gap='small'><Text weight='bold'>{t('store_private_key')}</Text>
                    <Text>{t('private_key_disappears')}</Text></Box><Button secondary alignSelf='start' label='I am done'
                                                   onClick={() => setPrivateKey('')}/></Box>}
            </Box> : <Text>{t('generate_api_key')}</Text>}
            <Button
                size='large'
                alignSelf='start'
                onClick={async () => {
                    const data = await generateApiKey();
                    setApiKey(data.apiKey);
                    setPrivateKey(data.privateKey);
                }}
                label={<Text color='white'>{apiKey ? t('Regenerate Key') : t('Generate Key')}</Text>}
                primary
                color={apiKey ? 'status-error' : 'status-ok'}
            />
        </Box>
    );
}