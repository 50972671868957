import React, {FC, ReactElement, useContext, useMemo} from 'react';
import {responsiveAreas, responsiveColumns, responsiveRows} from "./layout";
import {ActivePower} from "../../components/device/ActivePower";
import {PowerActivity} from "../../components/widgets/PowerActivity";
import {OutputParameters} from "../../components/device/OutputParameters";
import {InputParameters} from "../../components/device/InputParameters";
import {MeterTile} from "../../components/device/MeterTile";
import {DigitReadingTile} from "../../components/device/DigitReadingTile";
import {Grid, ResponsiveContext} from "grommet";
import {gql, useSubscription} from "@apollo/client";
import {SpinnerContainer} from "../../components/SpinnerContainer";
import {useTranslation} from "react-i18next";
import {setHour} from "../../services/helpers";

const FETCH_LATEST_DEVICE_METRIC = gql`
    subscription ($id: macaddr! $start: timestamp! $end: timestamp!) {
        device_metrics(where: {_and: [{device_id: {_eq: $id}},  {time: {_gte: $start}}, {time: {_lt: $end}}]} order_by: {time: desc} limit: 1) {
            time
            device {
                id
                status_id
                signal_strength
            }
            time
            u_a
            u_b
            u_c
            i_a
            i_b
            i_c
            p_peak
            p_reactive
            p_daily
            p_active
            p_accum
            pf
            freq
            efficiency
            temp
            pv1_u
            pv1_i
            pv2_u
            pv2_i
            pv3_u
            pv3_i
            pv4_u
            pv4_i
            pv5_u
            pv5_i
            pv6_u
            pv6_i
            pv7_u
            pv7_i
            pv8_u
            pv8_i
        }
    }
`;

const FETCH_DEVICE_METRICS = gql`
    subscription ($id: macaddr! $start: timestamp! $end: timestamp!) {
        historic_data: device_metrics(where: {_and: [{device_id: {_eq: $id}}, {time: {_gt: $start}}, {time: {_lt: $end}}]} order_by: {time: asc}) {
            time
            p_active
        }
    }
`;

interface Props {
    id: string,
    date: string
}
// TODO: Needs refactoring
export const TodayStats: FC<Props> = ({id, date}): ReactElement => {
    const { t } = useTranslation();
    const size = useContext(ResponsiveContext);
    const startHour = useMemo(() => setHour(date, 6), [date]);
    const endHour = useMemo(() => setHour(date, 23), [date]);
    const {data: latestMetric, loading: subscriptionLoading} = useSubscription(FETCH_LATEST_DEVICE_METRIC, {
        variables: {
            id,
            start: startHour,
            end: endHour
        },
    });
    const {data, loading} = useSubscription(FETCH_DEVICE_METRICS, {
        variables: {
            id,
            start: startHour,
            end: endHour
        }
    });

    const metrics = useMemo(() => latestMetric?.device_metrics[0] || {}, [latestMetric]);

    if (loading || subscriptionLoading) {
        return <SpinnerContainer height={{min: 'medium'}}/>
    }

    return (
        <Grid
            margin={{top: 'medium'}}
            align="center"
            gap="medium"
            // @ts-ignore
            rows={responsiveRows[size]}
            // @ts-ignore
            columns={responsiveColumns[size]}
            // @ts-ignore
            areas={responsiveAreas[size]}
        >
            <ActivePower gridArea='activePower' data={metrics}/>
            <PowerActivity startHour={startHour} endHour={endHour} data={data?.historic_data || []}
                           gridArea='powerActivity'/>
            <OutputParameters gridArea='outputParameters' data={metrics}/>
            <InputParameters gridArea='inputParameters' data={metrics}/>
            <MeterTile gridArea='efficiency' value={metrics.efficiency || 0} title={t('Efficiency')}/>
            <MeterTile gridArea='powerFactor' value={((metrics.pf || 0) * 100)} title={t('Power Factor')}/>
            <DigitReadingTile gridArea='powerPeak' value={metrics.p_peak || 0} title={t('Power Peak')} unit='kW'/>
            <DigitReadingTile gridArea='accumulatedPower' value={metrics.p_daily || 0} title={t('Accumulated Power')}
                              unit='kW'/>
            <DigitReadingTile gridArea='cabinetTemperature' value={metrics.temp || 0} title={t('Cabinet Temperature')}
                              unit='°C'/>
        </Grid>
    );
};