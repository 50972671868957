import React, {FC, ReactElement, useMemo, useState} from 'react';
import {AggregatedStatsTile} from "../place/AggregatedStatsTile";
import {useTranslation} from "react-i18next";

interface Props {
    powerToday: number,
    powerMonth: number,
}

export const PowerProducedWidget: FC<Props> = ({powerMonth, powerToday, ...rest}): ReactElement => {
    const {t} = useTranslation();
    const [option, setOption] = useState(powerToday);
    const scaled = useMemo(() => ({
        value: option > 1000 ? option / 1000 : option,
        unit: option > 1000 ? 'mW' : 'kW'
    }), [option]);
    return (
        <AggregatedStatsTile
            {...rest}
            value={`${option ? (scaled.value).toFixed(2) : 'N/A'} ${scaled.unit}`}
            options={[
                {plain: true, label: t('Today'), color: option === powerToday ? 'brand' : undefined, onClick: () => setOption(powerToday)},
                {plain: true, label: t('Last Month'), color: option === powerMonth ? 'brand' : undefined, onClick: () => setOption(powerMonth)}
            ]}
            title={t('Power Produced')}
        />
    );
};