import React, {FC, PureComponent, ReactElement} from 'react';
import {Line, LineChart, ResponsiveContainer, Tooltip, XAxis} from "recharts";
import moment from "moment-timezone";
import {scaleTime} from "d3-scale";
import {timeFormat} from "d3-time-format";
import {useTranslation} from "react-i18next";
import {Box, Paragraph, Text} from "grommet";
import {WeatherIcon} from "weather-react-icons";

const CustomTooltip: FC<any> = ({active, payload, label}) => {
    const { t } = useTranslation();
    if (active && payload && payload.length) {
        const content = payload[0].payload;
        return (
            <Box background='light-1' pad='small' round='xsmall' elevation='xsmall' gap='medium'>
                <Text weight='bold' size='large'>{moment.unix(content.dt).tz('Europe/Sofia').format('HH:mm')}</Text>
                <Box>
                    <WeatherIcon iconId={content.icon} name="owm" style={{fontSize: '42px', alignSelf: 'center'}}/>
                    {/* TODO: Capitlization works for English only */}
                    <Paragraph>{content.desc.replace(/(^\w|\s\w)/g, (m:any) => m.toUpperCase())}</Paragraph>
                    <Text>{t('Clouds')}: <strong>{content.clouds}%</strong></Text>
                    <Text>{t('Humidity')}: <strong>{content.humidity}%</strong></Text>
                    <Text>{t('Wind')}: <strong>{content.wind}m/s</strong></Text>
                </Box>
            </Box>
        );
    }

    return null;
};

class CustomizedLabel extends PureComponent {
    render() {
        // @ts-ignore
        const { x, y, stroke, value } = this.props;
        return (
            <text x={x} y={y} dy={-10} fill={stroke} fontSize={12} textAnchor="middle">
                {value.toFixed(0)} &deg;C
            </text>
        );
    }
}

interface Props {
    day:any
}

export const DayTemperatureChart: FC<Props> = ({day = []}): ReactElement => {
    const domainToday = day.length > 0 ?
        scaleTime().domain([moment.unix(day[0].dt).toDate(), moment.unix(day[day.length - 1].dt).toDate()]) :
        ['auto', 'auto']
    ;
    const timeFormatter = (tick: number) => timeFormat('%H:%M')(moment.unix(tick).toDate());

    return (
        <ResponsiveContainer width="100%" height={200}>
            <LineChart
                width={500}
                height={200}
                data={day}
                margin={{
                    top: 20,
                    right: 30,
                    left: 30,
                    bottom: 10,
                }}
            >
                {/* @ts-ignore */}
                <XAxis scale='time' type='number' domain={domainToday} dataKey='dt' tickFormatter={timeFormatter}/>
                <Tooltip content={<CustomTooltip/>}/>
                {/* @ts-ignore */}
                <Line type="monotone" dataKey="temp" stroke="#8884d8" label={<CustomizedLabel />} />
            </LineChart>
        </ResponsiveContainer>
    );
};