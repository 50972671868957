import React, {FC, ReactElement} from 'react';
import {Box, Text} from "grommet";
import moment from "moment";
import {WeatherIcon} from "weather-react-icons";
import {useTranslation} from "react-i18next";

interface Props {
    day: any,
    isSelected: boolean,
    onClick: () => void
}

export const WeatherDayCard: FC<Props> = ({day, isSelected, onClick}): ReactElement => {
    const {i18n} = useTranslation();
    const metric = day[0];
    const tempMaxAndMin = day.reduce(
        (acc: any, current: any) => {
            if (current.temp_max > acc.max) {
                acc.max = current.temp_max;
            }
            if (current.temp_min < acc.min) {
                acc.min = current.temp_min;
            }
            return acc;
        },
        {max: Number.MIN_VALUE, min: Number.MAX_VALUE},
    );
    return (
        <Box
            gap='small'
            align='center'
            onClick={onClick}
            background={isSelected ? 'light-3' : 'white'}
            elevation={isSelected ? 'xsmall' : undefined}
            round='small'
            pad='small'
        >
            <Text truncate>{moment.unix(metric.dt).locale(i18n.language).format('dddd')}</Text>
            <WeatherIcon iconId={metric.icon} name="owm" style={{fontSize: '42px'}}/>
            <Box direction='row-responsive' gap='small'>
                <Text size='small'>
                    {(Math.round(tempMaxAndMin.max * 10) / 10).toFixed(0)}
                    &deg;
                    C
                </Text>
                <Text size='small' style={{opacity: 0.4}}>
                    {(Math.round(tempMaxAndMin.min * 10) / 10).toFixed(0)}
                    &deg;
                    C
                </Text>
            </Box>
        </Box>
    )
};