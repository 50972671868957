import React, { FC, ReactElement } from 'react';
import {AuthPageWrapper} from "./components/AuthPageWrapper";
import {Box, Text} from "grommet";
import {RoutedAnchor} from "../../components/RoutedAnchor";
import {PasswordResetForm} from "./components/PasswordResetForm";
import {RouteComponentProps} from "react-router-dom";
import {useTranslation} from "react-i18next";

interface MatchParams {
    token: string;
}

export const PasswordResetPage: FC<RouteComponentProps<MatchParams>> = ({match}): ReactElement => {
    const { t } = useTranslation();
    return (
        <AuthPageWrapper withNav={false}>
            <Box pad='medium' gap='small'>
                <Text alignSelf='center'>{t('change_password')}</Text>
                <PasswordResetForm password_reset_token={match.params.token}/>
                <RoutedAnchor label={t('Sign in')} path='/sign-in' size='xsmall' alignSelf='center'/>
            </Box>
        </AuthPageWrapper>
    );
};