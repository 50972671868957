import React, {FC, ReactElement} from 'react';
import {Box, BoxProps, Text} from 'grommet';
import {Tile} from '../Tile';

interface Props extends BoxProps {
    value: number | string,
    title: string,
    unit: string
}

export const DigitReadingTile: FC<Props> = ({value, title, unit, ...rest}): ReactElement => {
    return (
        <Tile title={title} {...rest}>
            <Box direction="row" align="center" justify='center' pad={{bottom: 'xsmall'}}>
                <Text size="xxlarge" weight="bold">
                    {value}
                </Text>
                <Text size="small">{unit}</Text>
            </Box>
        </Tile>
    );
};