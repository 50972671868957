import React, {FC, ReactElement, useEffect, useState} from 'react';
import 'weather-react-icons/lib/css/weather-icons.css';
import axios from "axios";
import {useTranslation} from "react-i18next";
import moment from "moment";
import {Box, BoxProps} from "grommet";
import {WeatherDayCard} from './WeatherDayCard';
import {DayTemperatureChart} from "./DayTemperatureChart";
import {Tile} from "../../Tile";

interface Props extends BoxProps{
    lat: number,
    lon: number,
}

// Taken from https://github.com/wm4n/react-weather-widget
const groupByDay = (forecast: any) => {
    let firstMomentOfDay: any;
    let forecastOfDay: any = [];
    const forecastOfDayList: any = [];
    /* eslint-disable no-param-reassign */
    forecast.forEach((item: any, index: number) => {
        if (firstMomentOfDay === undefined) {
            firstMomentOfDay = moment.unix(item.dt);
            forecast[index].moment = firstMomentOfDay;
            forecastOfDay.push(item);
        } else {
            const currentMoment = moment.unix(item.dt);
            forecast[index].moment = currentMoment;
            if (firstMomentOfDay.isSame(currentMoment, 'day')) {
                forecastOfDay.push(item);
            } else {
                forecastOfDayList.push(forecastOfDay);
                forecastOfDay = [];
                forecastOfDay.push(item);
                firstMomentOfDay = currentMoment;
            }
        }
    });
    return forecastOfDayList;
}

export const WeatherForecastWidget: FC<Props> = ({lat, lon, ...rest}): ReactElement => {
    const {t, i18n} = useTranslation();
    const [forecast, setForecast] = useState([]);
    const [selectedDay, setSelectedDay] = useState(0);
    const [error, setError] = useState('');

    useEffect(() => {
        (async () => {
            try {
                const response = await axios.get(
                    'https://api.openweathermap.org/data/2.5/forecast',
                    {
                        params: {
                            lat,
                            lon,
                            lang: i18n.language,
                            appid: process.env.REACT_APP_OPEN_WEATHER_API_KEY,
                            units: 'metric',
                        },
                    },
                );
                // console.log('res', response.data);
                const transformData = await response.data.list.map((data: any) => ({
                    dt: data.dt,
                    temp: data.main.temp,
                    temp_min: data.main.temp_min,
                    temp_max: data.main.temp_max,
                    humidity: data.main.humidity,
                    icon: data.weather[0].id,
                    desc: data.weather[0].description,
                    clouds: data.clouds.all,
                    wind: data.wind.speed,
                }));
                setForecast(groupByDay(transformData));
                // console.log('transformed', transformData);
                // console.log('grouped', groupByDay(transformData));
            } catch (err: any) {
                setError(err.stack);
            }
        })();
    }, [lat, lon, i18n.language]);
    return (
        <Tile {...rest} title={t('Weather Forecast')}>
            <Box gap='medium' align='center' flex={false} pad={{bottom: 'medium'}}>
                <DayTemperatureChart day={forecast[selectedDay]}/>
                <Box direction='row' gap='medium'>
                    {forecast.map((day: any, index) =>
                        <WeatherDayCard key={index} day={day}
                                        onClick={() => setSelectedDay(index)}
                                        isSelected={index === selectedDay}/>)}
                </Box>
            </Box>
        </Tile>
    );
};