import React, {FC, ReactElement, useEffect, useMemo, useState} from 'react';
import {RouteComponentProps, useHistory} from "react-router-dom";
import {gql, useMutation, useQuery} from "@apollo/client";
import {useFormik} from "formik";
import {Box, Button, FormField, Heading, Select, Text} from "grommet";
import {PageHeader} from "../../components/PageHeader";
import * as Yup from "yup";
import {SpinnerContainer} from "../../components/SpinnerContainer";
import {Alert} from "grommet-icons";
import {useTranslation} from "react-i18next";
import {FirmwareUpdate} from "../../components/device/FirmwareUpdate";
import {RestartDevice} from "../../components/device/RestartDevice";
import {Modal} from "../../components/Modal";
import {useUserPermissions} from "../../components/device/userPermissions";

const validationSchema = Yup.object().shape({
    manufacturer_id: Yup.number(),
    model_id: Yup.number(),

});

interface FormFields {
    manufacturer_id: number,
    model_id: number
}

const initialValues: FormFields = {
    manufacturer_id: 0,
    model_id: 0
};

const UPDATE_DEVICE_MODEL = gql`
    mutation ($id: macaddr! $model_id: Int!) {
        device: update_device_by_pk(pk_columns: {id: $id} _set: {model_id: $model_id}) {
            id
            model_id
            model {
                id
                name
            }
        }
    }
`;

const RESTART_DEVICE = gql`
    mutation ($id: String!) {
        restart_device(id: $id)
    }
`;

const FETCH_MANUFACTURERS_AND_MODELS = gql`
    query($id: macaddr! $user_id: uuid!) {
        userId @client @export(as: "user_id")
        device: device_by_pk(id: $id) {
            id
            model_id
            firmware_version
            model {
                id
                manufacturer_id
            }
            available_firmwares(order_by: {created_at:desc} limit: 1) {
                version
                changelog
            }
            user_id
            user_devices(where: {_and: [{user_id: {_eq: $user_id}}, {device_id: {_eq: $id}}]}) {
                view
                edit
            }
        }
        model {
            id
            name
            manufacturer_id
        }
        manufacturer {
            id
            name
        }
    }
`;

interface MatchParams {
    id: string;
}

export const EditDevicePage: FC<RouteComponentProps<MatchParams>> = ({match}): ReactElement => {
    const { t } = useTranslation();
    const [showModal, setShowModal] = useState(false);
    const history = useHistory();
    const {data, loading} = useQuery(FETCH_MANUFACTURERS_AND_MODELS, {variables: {id: match.params.id}});
    const [updateDevice] = useMutation(UPDATE_DEVICE_MODEL);
    const [restartDevice] = useMutation(RESTART_DEVICE);
    const deviceInitialValues = useMemo(() => {
        if (data?.device?.model_id) {
            return {model_id: data.device.model_id, manufacturer_id: data.device.model.manufacturer_id};
        }
        return {};
    }, [data]);
    const {errors, values, handleSubmit, setFieldValue, submitForm} = useFormik({
        initialValues: {...initialValues, ...deviceInitialValues},
        enableReinitialize: true,
        validationSchema,
        onSubmit: async (values, actions) => {
            // console.log(values);
            const res = await updateDevice({variables: {id: match.params.id, model_id: values.model_id}});
            console.log('res', res);
            await restartDevice({variables: {id: match.params.id}});
            history.push(`/devices/${match.params.id}`);
        }
    });
    const {canEdit} = useUserPermissions(data?.userId, data?.device);
    useEffect(() => {
        if (!canEdit) {
            // @ts-ignore
            history.goBack();
        }
    }, [canEdit, history]);

    if (loading) {
        return <SpinnerContainer/>
    }

    return (
        <Box flex={false} pad="medium">
            <PageHeader
                name={`${t('Configure Device')} ${match.params.id}`}
            />
            {showModal && <Modal
                title={t('change_device_model')}
                message={t('change_device_warning')}
                onClose={() => {setShowModal(false)}}
                onAction={async () => {
                    setShowModal(false);
                    await submitForm();
                }}
                buttonProps={{color: 'brand', label: t('Save')}}
            />}
            <Box direction='row-responsive' gap='medium'>
                <Box>
                    <Heading level={3} fill>{t('select_connected_device')}</Heading>
                    <form onSubmit={handleSubmit}>
                        <FormField label={t('Manufacturer')} error={errors.manufacturer_id}>
                            <Select
                                name='manufacturer_id'
                                options={data.manufacturer}
                                // @ts-ignore
                                value={values.manufacturer_id}
                                labelKey='name'
                                valueKey={{key: 'id', reduce: true}}
                                onChange={({value}) => setFieldValue('manufacturer_id', value)}
                            />
                        </FormField>
                        <FormField label={t('Model')} error={errors.model_id}>
                            <Select
                                name='model_id'
                                options={data.model.filter((model: any) => model.manufacturer_id === values.manufacturer_id)}
                                // @ts-ignore
                                value={values.model_id}
                                labelKey='name'
                                disabled={!values.manufacturer_id}
                                valueKey={{key: 'id', reduce: true}}
                                onChange={({value}) => setFieldValue('model_id', value)}
                            />
                        </FormField>
                        <Box margin={{left: 'small'}} direction='row' gap='small'><Alert color='status-warning'/><Text> {t('wrong_device_selection')}</Text></Box>

                        <Button primary label={t('Save')} onClick={() => setShowModal(true)} margin={{top: 'medium'}}/>
                    </form>
                </Box>
               <RestartDevice id={match.params.id}/>
                <FirmwareUpdate
                    firmware={data?.device?.firmware_version}
                    latestFirmware={data?.device?.available_firmwares[0]?.version}
                    id={match.params.id}
                    changelog={data?.device?.available_firmwares[0]?.changelog}
                />
            </Box>
        </Box>
    );
};