import React, { FC, ReactElement } from 'react';
import {Box, BoxProps, Heading, Text} from "grommet";
import {RoutedAnchor} from "./RoutedAnchor";

interface Props {
    error: Error;
    componentStack: string | null;
    eventId: string | null;
    resetError(): void;
    boxProps?: BoxProps
}

export const FatalError: FC<Props> = ({boxProps = {}}): ReactElement => {
    return (
        <Box pad='medium' align='center' justify='center' {...boxProps}>
            <Heading level={1} size='xlarge' margin={{vertical: 'small'}}>OOPS!</Heading>
            <Text size='xlarge'>something went wrong</Text>
            <RoutedAnchor path='/' method='replace' label='Go back' margin={{top: 'medium'}} onClick={() => window.location.reload()}/>
        </Box>
    );
};