import React, { FC, ReactElement } from 'react';
import {Box, Button, ButtonProps, Layer, Paragraph, Text} from "grommet";
import {useTranslation} from "react-i18next";

interface Props {
    title: string,
    message?: string,
    onClose: () => void,
    onAction: () => void,
    buttonProps: ButtonProps
}

export const Modal: FC<Props> = ({title, message, onClose, onAction, buttonProps}): ReactElement => {
    const { t } = useTranslation();
    return (
        <Layer>
            <Box
                align="center"
                justify="center"
                gap="small"
                alignSelf="center"
                pad="large"
            >
                <Text weight='bold'>{title}</Text>
                <Paragraph>{message}</Paragraph>
                <Box direction='row' gap='small'>
                    <Button secondary label={t('Cancel')} onClick={onClose}/>
                    {/* @ts-ignore */}
                    <Button primary {...buttonProps} onClick={onAction}/>
                </Box>
            </Box>
        </Layer>
    );
};