import React, {FC, ReactElement, useEffect} from 'react';
import {Spinner} from "grommet";
import {useAuth} from "../services/auth";
import {FormikErrors, FormikHelpers, FormikState, FormikTouched} from "formik";

type Props = {
}

const mockFormikHelpers: FormikHelpers<any> = {
    resetForm(nextState: Partial<FormikState<any>> | undefined): void {
    },
    setErrors(errors: FormikErrors<any>): void {
    },
    setFieldError(field: string, message: string | undefined): void {
    },
    setFieldTouched(field: string, isTouched: boolean | undefined, shouldValidate: boolean | undefined): void {
    },
    setFieldValue(field: string, value: any, shouldValidate: boolean | undefined): void {
    },
    setFormikState(f: FormikState<any> | ((prevState: FormikState<any>) => FormikState<any>), cb: (() => void) | undefined): void {
    },
    setStatus(status: any): void {
    },
    setSubmitting(isSubmitting: boolean): void {
    },
    setTouched(touched: FormikTouched<any>, shouldValidate: boolean | undefined): void {
    },
    setValues(values: React.SetStateAction<any>, shouldValidate: boolean | undefined): void {
    },
    submitForm(): Promise<void> {
        return Promise.resolve(undefined);
    },
    validateField(field: string): void {
    },
    validateForm(values: any): Promise<any> {
        return Promise.resolve(undefined);
    }
}

export const DemoPage: FC<Props> = (): ReactElement => {
    const {login} = useAuth();
    useEffect(() => {
        (async () => {
            await login({
                    email: 'demo@mitabits.com',
                    password: 'demo@mitabits.com'
                }, mockFormikHelpers
            );
        })();
    }, [login]);
    return (<Spinner/>);
};