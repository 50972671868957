import React, { FC, ReactElement } from 'react';
import {Box, Button, FormField, TextInput} from "grommet";
import {PageHeader} from "../components/PageHeader";
import {useHistory} from "react-router-dom";
import {SearchLocationInput} from "../components/place/SearchLocationInput";
import * as Yup from "yup";
import {useFormik} from "formik";
import {DeviceSelect} from "../components/place/DeviceSelect";
import {gql, useMutation} from "@apollo/client";
import {useTranslation} from "react-i18next";

const validationSchema = Yup.object().shape({
    timezone: Yup.string(),
    price_per_kwh: Yup.number().required('Required'),
    name: Yup.string().max(255).required('Required'),
    city: Yup.string().max(255).required('Required'),
    country: Yup.string().max(255).required('Required'),
});

interface FormFields {
    name: string,
    city: string,
    country: string,
    timezone: string,
    price_per_kwh: number,
    latitude: number,
    longitude: number,
    devices: string[]
}

const initialValues: FormFields = {
    name: '',
    city: '',
    country: '',
    timezone: 'Europe/Sofia',
    price_per_kwh: 0.1982,
    latitude: 0,
    longitude: 0,
    devices: []
};

type Props = {
}

const CREATE_PLACE = gql`
    mutation ($data: place_insert_input!) {
        insert_place_one(object: $data) {
            id
            name
        }
    }
`;

const UPDATE_DEVICES_PLACE = gql`
    mutation ($devices: [macaddr]! $place: Int!) {
        update_device(where: {id: {_in: $devices}} _set: {place_id: $place}) {
            affected_rows
        }
    }
`;


// TODO: Fix error shown when you have already selected a location
export const CreatePlacePage: FC<Props> = (): ReactElement => {
    const { t } = useTranslation();
    const history = useHistory();
    const [createPlace] = useMutation(CREATE_PLACE);
    const [updateDevices] = useMutation(UPDATE_DEVICES_PLACE);
    const {errors, values, handleChange, handleSubmit, setFieldValue} = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values, actions) => {
            const {devices, ...data} = values;
            // console.log(values);
            const res = await createPlace({variables: {data}});
            // console.log('place create', res);
            const res2 = await updateDevices({variables: {devices, place: res.data.insert_place_one.id}});
            // console.log('device update', res2);
            history.push('/places');
        }
    });
    return (
        <Box flex={false} pad="medium">
            <PageHeader
                name={`${t("Create")} ${t('Place')}`}
            />
            <Box flex={false}>
                <form onSubmit={handleSubmit}>
                    <FormField label={t('Name')} error={errors.name}>
                        <TextInput
                            name="name"
                            type="text"
                            value={values.name || ""}
                            onChange={handleChange}
                        />
                    </FormField>
                    <FormField label={t('price_per_kwh')} error={errors.price_per_kwh}>
                        <TextInput
                            name="price_per_kwh"
                            type="number"
                            min="0.00"
                            step="0.0001"
                            max="1.00"
                            value={values.price_per_kwh || ""}
                            onChange={handleChange}
                        />
                    </FormField>
                    <Box pad={{horizontal: 'small'}}>
                        <PageHeader
                            heading={{level: 3}}
                            name={t('Select Location')}
                        />
                        <SearchLocationInput onSelect={(suggestion) => {
                            setFieldValue('latitude', suggestion.lat);
                            setFieldValue('longitude', suggestion.lon);
                            setFieldValue('city', suggestion.address.city || suggestion.address.village || '');
                            setFieldValue('country', suggestion.address.country);
                        }}
                                             error={errors.city}
                        />
                        {values.city && <Box margin={{top: 'medium'}}>
                            <FormField label={t('city_village')} error={errors.city}>
                                <TextInput
                                    name="city"
                                    type="text"
                                    disabled
                                    value={values.city || ""}
                                    onChange={handleChange}
                                />
                            </FormField>
                            <FormField label={t('Country')} error={errors.country}>
                                <TextInput
                                    name="country"
                                    type="text"
                                    disabled
                                    value={values.country || ""}
                                    onChange={handleChange}
                                />
                            </FormField>
                            <Box direction='row' gap='small'>
                                <FormField label="Latitude" error={errors.latitude}>
                                    <TextInput
                                        name="latitude"
                                        type="text"
                                        disabled
                                        value={values.latitude || ""}
                                        onChange={handleChange}
                                    />
                                </FormField>
                                <FormField label="Longitude" error={errors.longitude}>
                                    <TextInput
                                        name="longitude"
                                        type="text"
                                        disabled
                                        value={values.longitude || ""}
                                        onChange={handleChange}
                                    />
                                </FormField>
                            </Box>
                            <FormField label="Timezone" error={errors.timezone}>
                                <TextInput
                                    name="timezone"
                                    type="text"
                                    disabled
                                    value={values.timezone || ""}
                                    onChange={handleChange}
                                />
                            </FormField>
                        </Box>}
                    </Box>
                    <Box pad={{horizontal: 'small'}}>
                        <PageHeader
                            heading={{level: 3}}
                            name={t('Devices')}
                        />
                        <DeviceSelect onSelect={(selected) => setFieldValue('devices', selected)}/>
                    </Box>
                    <Button primary label={t('Create')} type='submit' margin={{top: 'medium'}}/>
                </form>
            </Box>
        </Box>
    );
};