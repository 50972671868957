import React, {FC, ReactElement, useEffect} from 'react';
import {Box} from "grommet";
import {gql, useQuery} from "@apollo/client";
import {PageHeader} from "../components/PageHeader";
import {SpinnerContainer} from "../components/SpinnerContainer";
import {NoApiKeyNotification} from "../components/dashboard/NoApiKeyNotification";
import {useHistory} from "react-router-dom";

type Props = {
}

const FETCH_USER = gql`
    query {
        user {
            id
            name
            api_key
        }
    }
`;

export const DashboardPage: FC<Props> = (): ReactElement => {
    const {data, loading, error} = useQuery(FETCH_USER);
    console.log('data', data, loading, error);
    const history = useHistory();

    useEffect(() => {
        history.push('/devices')
    }, [history]);

    if (loading) {
        return <SpinnerContainer/>;
    }

    return (
        <Box flex={false} pad="medium">
            <NoApiKeyNotification show={!data?.user[0]?.api_key}/>
            <PageHeader name="Dashboard" />
            <Box flex={false}></Box>
        </Box>
    );
};