import React, {FC, ReactElement, useMemo} from 'react';
import {Box} from "grommet";
import {NavButton} from "./NavButton";
import {useLocation} from "react-router-dom";
import {useTranslation} from "react-i18next";

export const AuthNav: FC = (): ReactElement => {
    const { t } = useTranslation();
    const {pathname} = useLocation();
    const inSignInPage = useMemo(() => pathname.includes('/sign-in'), [pathname]);
    return (
        <Box direction='row' fill='horizontal'>
            <NavButton position='left' active={inSignInPage} label={t('Sign in')} path='/sign-in'/>
            <NavButton position='right' active={!inSignInPage} label={t('Sign up')} path='/sign-up'/>
        </Box>
    );
};