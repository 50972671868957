import React from 'react';
import {Heading, Main} from "grommet";
import routes from "../routes";
import {Logo} from "../components/Logo";
import {RoutedAnchor} from "../components/RoutedAnchor";
import {FatalError} from "../components/FatalError";
import {ErrorBoundary} from "@sentry/react";

export const UnauthorizedLayout = () => (
    <Main background='light-3' justify='center' align='center' pad='large'>
        <RoutedAnchor
            margin={{vertical: 'medium'}}
            icon={<Logo color='light-3'/>}
            color='black'
            // @ts-ignore
            style={{textDecoration: 'none'}}
            label={<Heading level={1} size='small'>mitabits</Heading>}
            path='/'
        />
        <ErrorBoundary fallback={(props) => <FatalError {...props} boxProps={{height: {min: 'medium'}}}/>}>
            {routes}
        </ErrorBoundary>
    </Main>
);