import React, {FC, ReactElement} from 'react';
import {useFormik} from "formik";
import * as Yup from 'yup';
import {Button, FormField, Spinner, TextInput} from "grommet";
import {GeneralErrorField} from "./GeneralErrorField";
import {useAuth} from "../../../services/auth";
import {useTranslation} from "react-i18next";

const validationSchema = Yup.object().shape({
    email: Yup.string()
        .email('Invalid email')
        .required('Required'),
});

interface FormFields {
    email: string
}

const initialValues = {
    email: ''
};

export const RequestPasswordResetForm: FC = (): ReactElement => {
    const { t } = useTranslation();
    const {requestPasswordReset} = useAuth();
    const {errors, values, handleChange, handleSubmit, status, isSubmitting} = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values: FormFields, actions) => {
            console.log('submit', values);
            await requestPasswordReset(values, actions);
        }
    });

    return (
        <form onSubmit={handleSubmit}>
            <FormField label="Email" error={errors.email}>
                <TextInput
                    name="email"
                    type="email"
                    value={values.email || ""}
                    onChange={handleChange}
                />
            </FormField>
            <GeneralErrorField status={status}/>
            <Button type='submit'
                    label={t('Reset')}
                    primary
                    fill='horizontal'
                    hoverIndicator
                    icon={isSubmitting ? <Spinner/> : undefined}
            />
        </form>
    );
};