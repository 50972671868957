import React, {FC, ReactElement, useContext} from 'react';
import {Box, ResponsiveContext, Text} from "grommet";
import {useHistory} from 'react-router-dom';

interface Props {
    position: 'left' | 'right',
    active: boolean,
    label: string,
    path: string
};

export const NavButton: FC<Props> = ({position, active, label, path}): ReactElement => {
    const history = useHistory();
    const size = useContext(ResponsiveContext);
    return (
       <Box
           round={{
               size: 'xsmall',
               corner: position === 'left' ? 'top-left' : 'top-right'
           }}
           fill
           align='center'
           pad={size === 'small' || size === 'xsmall' ? 'medium' : 'small'}
           border={{
               side: 'bottom',
               size: active ? 'medium' : 'small',
               color: active ? 'accent-1' : 'border'
           }}
           style={{cursor: 'pointer'}}
           onClick={() => history.push(path)}
       >
           <Text weight='bold' color={active ? 'accent-1' : 'dark-2'}>{label}</Text>
       </Box>
   )
};