import React, {FC, ReactElement, isValidElement} from 'react';

import {Box, BoxProps, Heading, HeadingProps} from 'grommet';

interface Props extends BoxProps {
    name: string,
    action?: ReactElement,
    heading?: HeadingProps | ReactElement
}

export const PageHeader: FC<Props> = ({name, action, heading = {}, ...rest}): ReactElement => (
    <Box
        flex={false}
        margin={{bottom: 'small'}}
        justify="between"
        direction="row"
        border={{side: 'bottom', color: 'light-4'}}
        {...rest}
    >
        {isValidElement(heading) ? heading :
            <Heading level={2} size="xsmall" {...heading}>
                {name}
            </Heading>}
        <Box alignSelf="center">
            {action}
        </Box>
    </Box>
);
