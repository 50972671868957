import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {ApolloProvider} from "@apollo/client";
import {client} from "./config/apollo";
import './config/i18n';
import './config/sentry';
import {SpinnerContainer} from "./components/SpinnerContainer";

ReactDOM.render(
    <React.StrictMode>
        <Suspense fallback={<SpinnerContainer/>}>
            <ApolloProvider client={client}>
                <App/>
            </ApolloProvider>
        </Suspense>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
