import React, {FC, ReactElement, useContext, useMemo} from 'react';
import {PageHeader} from "../../components/PageHeader";
import {useTranslation} from "react-i18next";
import {Box, Button, Grid, ResponsiveContext, Text} from "grommet";
import {RouteComponentProps, useHistory} from "react-router-dom";
import {gql, useQuery} from "@apollo/client";
import {SpinnerContainer} from "../../components/SpinnerContainer";
import moment from "moment-timezone";
import {ActivePower} from "../../components/place/ActivePower";
import {Configure, Directions} from "grommet-icons";
import {PowerActivity} from "../../components/widgets/PowerActivity";
import {setHour} from "../../services/helpers";
import {WeatherForecastWidget} from "../../components/widgets/WeatherForecastWidget/WeatherForecastWidget";
import {responsiveAreas, responsiveColumns, responsiveRows} from "./layout";
import {DeviceCard} from "../../components/place/DeviceCard";
import {PowerProducedDaily} from "../../components/widgets/PowerProducedDaily";
import {RevenueWidget} from "../../components/widgets/RevenueWidget";
import {CO2Widget} from "../../components/widgets/CO2Widget";
import {PowerProducedWidget} from "../../components/widgets/PowerProducedWidget";
import {DigitReadingTile} from "../../components/device/DigitReadingTile";

interface MatchParams {
    id: string;
}

const FETCH_PLACE = gql`
    query ($id: Int! $start: timestamp! $end: timestamp, $daily_start: timestamp){
        place: place_by_pk(id: $id) {
            id
            name
            latitude
            longitude
            city
            country
            price_per_kwh
            monthly_power_production
            devices {
                id
                connection_active
                signal_strength
                status
                model {
                    id
                    name
                    rated_power
                    model_type {
                        id
                        name
                    }
                    manufacturer {
                        id
                        name
                    }
                }
                device_metrics(where: {time: {_gt: $start}} order_by: {time: desc} limit: 1) {
                    time
                    p_daily
                }
                last_active: device_metrics(order_by: {time: desc} limit: 1) {
                    time
                }
            }
        }
        place_power_activity(args: {placeid: $id, start_date: $start}) {
            time
            p_active
        }
        place_power_activity_daily(args: {placeid: $id, start_date: $daily_start}) {
            time
            p_daily
        }
    }
`;

export const PlacePage: FC<RouteComponentProps<MatchParams>> = ({match}): ReactElement => {
    const {t} = useTranslation();
    const size = useContext(ResponsiveContext);
    const weekBefore = useMemo(() => moment().subtract('7', 'days').toISOString(), []);
    const [startDate, setStartDate] = React.useState(moment().toISOString());
    const [endDate, setEndDate] = React.useState(moment().toISOString());
    const startHour = useMemo(() => setHour(startDate, 6), [startDate]);
    const endHour = useMemo(() => setHour(endDate, 23), [endDate]);
    const {data, loading, error} = useQuery(FETCH_PLACE, {
        variables: {
            id: match.params.id,
            start: startHour,
            end: endHour,
            daily_start: weekBefore
        }
    });
    const history = useHistory();
    // console.log('data', data, loading, error);
    if (loading) {
        return <SpinnerContainer/>
    }
    const powerGenerated = data?.place?.devices?.reduce((acc:any , current: any) => acc + current?.device_metrics[0]?.p_daily || 0, 0);
    const ratedPower = data?.place?.devices?.reduce((acc:any , current: any) => acc + current?.model?.rated_power, 0);
    return (
        <Box flex={false} pad="medium">
            <PageHeader
                name={`${t('Place')} ${data?.place?.name}`}
                action={<Button icon={<Configure color='brand' size='30px'/>} plain
                                onClick={() => history.push(`/places/${match.params.id}/edit`)}/>}
            />
            <Box border={{side: 'bottom', color: 'light-4'}} pad={{bottom: 'medium'}}>
                <Box direction='row' gap='small' align='center'>
                    <Text>{t('Location')}: <strong>{`${data?.place?.city}, ${data?.place?.country}`}</strong></Text>
                    <Button plain icon={<Directions color='brand' size='32px'/>} href={`//google.com/maps/?q=${data?.place?.latitude},${data?.place?.longitude}`} target='_blank'/>
                </Box>
                <Text size='xlarge' margin={{top: 'medium'}}>{t('Devices')}</Text>
                <Box wrap align='start' direction='row' gap='medium'>
                    {data?.place?.devices?.map((device:any) =>
                        <DeviceCard key={device.id}
                                    {...device}
                                    boxProps={{
                                        margin: {top: 'medium'},
                                        fill: size === 'small' ? 'horizontal' : false,
                                        width: {max: size === 'small' ? undefined : 'medium'},
                                        onClick: () => history.push(`/devices/${device.id}`)
                                    }}
                        />)}
                </Box>
            </Box>
            <Grid
                margin={{top: 'medium'}}
                align="center"
                gap="medium"
                // @ts-ignore
                rows={responsiveRows[size]}
                // @ts-ignore
                columns={responsiveColumns[size]}
                // @ts-ignore
                areas={responsiveAreas[size]}
            >
                {/* @ts-ignore */}
                <RevenueWidget powerToday={powerGenerated} powerMonth={data?.place?.monthly_power_production} pricePerKwh={data?.place?.price_per_kwh} gridArea='revenue' background='accent-1'/>
                {/* @ts-ignore */}
                <CO2Widget powerToday={powerGenerated} powerMonth={data?.place?.monthly_power_production} gridArea='CO2' background='neutral-4'/>
                {/* @ts-ignore */}
                <PowerProducedWidget powerToday={powerGenerated} powerMonth={data?.place?.monthly_power_production} gridArea='powerProduced' background='accent-3'/>
                <DigitReadingTile gridArea='installedPower' value={ratedPower.toFixed(2)} title={t('Installed Power')}
                                  unit='kWp' background='accent-4'/>
                <WeatherForecastWidget lat={data?.place?.latitude} lon={data?.place?.longitude} gridArea='weatherForecast'/>
                <ActivePower id={match.params.id} height={{min: 'medium'}} gridArea='activePower'/>
                {/* TODO: Use https://recharts.org/en-US/examples/StackedAreaChart */}
                <PowerActivity startHour={startHour} endHour={endHour} gridArea='powerActivity'
                               data={data.place_power_activity}/>
                <PowerProducedDaily startHour={startHour} endHour={endHour} data={data.place_power_activity_daily} gridArea='powerProducedDaily'/>
            </Grid>
        </Box>
    );
};