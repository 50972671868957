import React, {FC, ReactElement, useRef, useState} from 'react';

import { Box, Drop } from 'grommet';
import {Page} from "./Sidebar";
import {RoutedButton} from "../../components/RoutedButton";
import {useTranslation} from "react-i18next";


export const TooltipButton: FC<Page> = ({ icon: Icon, label, path, active }): ReactElement => {
    const { t } = useTranslation();
    const [over, setOver] = useState<boolean>(false);
    const tooltipColor = { color: 'accent-1', opacity: 0.9 };
    const ref = useRef<HTMLButtonElement>(null);
    return (
        <Box width="100%" background={active ? 'accent-1' : 'none'}>
            {/* eslint-disable-next-line jsx-a11y/mouse-events-have-key-events */}
            <RoutedButton
                // @ts-ignore
                ref={ref}
                path={path}
                onMouseOver={() => setOver(true)}
                onMouseLeave={() => setOver(false)}
                fill="horizontal"
                hoverIndicator={tooltipColor}
                plain
            >
                {({ hover = false }) => (
                    <Box pad={{ vertical: 'small' }} align="center">
                        <Icon color={hover || active ? 'black' : 'white'}/>
                    </Box>
                )}
            </RoutedButton>
            {ref.current && over && (
                <Drop align={{ left: 'right' }} target={ref.current} plain>
                    <Box
                        animation="slideRight"
                        margin="xsmall"
                        pad="small"
                        background={tooltipColor}
                        round={{ size: 'medium', corner: 'right' }}
                    >
                        {t(label)}
                    </Box>
                </Drop>
            )}
        </Box>
    );
};