import React, { FC, ReactElement } from 'react';
import {Box, Heading} from "grommet";
import {Clear} from "grommet-icons";

interface Props {
}

export const NoMatchPage: FC<Props> = (): ReactElement => {
    return (
        <Box flex={false} pad="large" align='center' justify='center' direction='row' gap='small'>
            <Clear size='xlarge' color='status-error'/><Heading>404 Not Found</Heading>
        </Box>
    );
};