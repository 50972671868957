import React, {FC, ReactElement} from 'react';
import {Box, BoxProps, Meter, Stack, Text} from 'grommet';
import { Tile } from '../Tile';

interface Props extends BoxProps {
    value: number,
    title: string
}

export const MeterTile: FC<Props> = ({value, title, ...rest }): ReactElement => {
    return (
        <Tile title={title} {...rest}>
            <Box align="center">
                <Stack anchor="center">
                    <Meter
                        type="circle"
                        background="light-2"
                        values={[{ value }]}
                        size="xsmall"
                        thickness="small"
                    />
                    <Box direction="row" align="center" pad={{ bottom: 'xsmall' }}>
                        <Text size="xlarge" weight="bold">
                            {value.toFixed(1)}
                        </Text>
                        <Text size="small">%</Text>
                    </Box>
                </Stack>
            </Box>
        </Tile>
    );
};