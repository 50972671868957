import React, {FC, forwardRef, ReactElement, SyntheticEvent} from 'react';
import {Button, ButtonProps} from "grommet";
import { useHistory } from "react-router-dom";


interface Props extends ButtonProps {
    path: string,
    method?: 'push' | 'replace',
    onClick?: (event: SyntheticEvent) => void,
}

export const RoutedButton: FC<Props> = forwardRef(({path, method = 'push', onClick = () => {}, ...rest}, ref): ReactElement => {
    const history = useHistory();
    return (
        <Button
            // @ts-ignore
            ref={ref}
            {...rest}
            onClick={(event, ...args) => {
                const modifierKey = event.ctrlKey || event.metaKey;
                // if the user right-clicked in the Anchor we should let it go
                if (modifierKey) {
                    return;
                }
                event.preventDefault();
                history[method](path);
                onClick(event, ...args);
            }}
        />
    );
});
