import React, {FC, ReactElement, useCallback, useMemo} from "react";
import {Box, BoxProps, Menu, Nav, Sidebar as Sidebox, Text} from "grommet";
import {Dashboard, Language, Launch, LocationPin, Notification, Support, User} from "grommet-icons";
import {TooltipButton} from "./TooltipButton";
import {matchPath, useHistory, useLocation} from "react-router-dom";
import {useAuth} from "../../services/auth";
import {Logo} from "../../components/Logo";
import {RoutedAnchor} from "../../components/RoutedAnchor";
import {useTranslation} from "react-i18next";

export interface Page {
    label: string,
    path: string,
    icon: any,
    active?: boolean,
    exact?: boolean
}
// Don't forget to translate page labels
const pages: Page[] = [
    // {
    //     label: 'Dashboard',
    //     path: '/',
    //     icon: Dashboard,
    //     exact: true
    // },
    {
        label: 'Places',
        path: '/places',
        icon: LocationPin
    },
    {
        label: 'Devices',
        path: '/devices',
        icon: Launch
    },
    {
        label: 'Support',
        path: '/support',
        icon: Support
    }
];


const SidebarFooter: FC = (): ReactElement => {
    const { t, i18n } = useTranslation();
    const {logout} = useAuth();
    const history = useHistory();

    const changeLanguage = useCallback((lang: string) => i18n.changeLanguage(lang), [i18n]);

    return (
        <Box gap='small'>
            {/*<Button alignSelf='center' icon={<Notification/>} badge/>*/}
            <Menu
                dropAlign={{ top: 'bottom', right: 'right' }}
                icon={false}
                label={<Language/>}
                // dropBackground={{ color: "white", opacity: "medium" }}
                items={[
                    { label: 'EN', onClick: () => changeLanguage('en'), active: i18n.language.includes('en') },
                    { label: 'БГ', onClick: () => changeLanguage('bg'), active: i18n.language.includes('bg') },
                ]}
                alignSelf='center'
            />
            <Menu
                dropAlign={{ top: 'bottom', right: 'right' }}
                icon={false}
                label={<User/>}
                // dropBackground={{ color: "white", opacity: "medium" }}
                items={[
                    { label: t('Profile'), onClick: () => {history.push('/profile')} },
                    { label: t('Logout'), onClick: logout },
                ]}
                alignSelf='center'
            />
        </Box>
    );
}

const markActiveMenu = (location: Location | any) => pages.map(page =>
    ({
        ...page,
        active: matchPath(location.pathname, {path: page.path, exact: page.exact}) != null
    }))


export const Sidebar: FC<BoxProps> = (props): ReactElement => {
    const location = useLocation();
    const menus = useMemo<Page[]>(() => (markActiveMenu(location)), [location]);
    return (
        <Sidebox
            pad='none'
            background="brand"
            elevation='small'
            // @ts-ignore
            header={<RoutedAnchor color='white' path='/' style={{textDecoration: 'none'}}><Box alignSelf='center' pad='small' align='center' gap='small'><Logo color='brand'/><Text size='xsmall' weight='bold'>mitabits</Text></Box></RoutedAnchor>}
            footer={<SidebarFooter/>}
            {...props}
        >
            <Nav gap="small">
                {menus.map((menu) => (
                    <TooltipButton key={menu.label} {...menu} />
                ))}
            </Nav>
        </Sidebox>
    );
}