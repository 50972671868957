import React, {FC, ReactElement, useState} from 'react';
import {Box, Button, Heading, Notification, Paragraph, Spinner, Text} from "grommet";
import {Checkmark, StatusWarning} from "grommet-icons";
import {gql, useMutation} from "@apollo/client";
import {Modal} from "../Modal";
import {useTranslation} from "react-i18next";

const UPDATE_FIRMWARE = gql`
    mutation ($id: String!) {
        update_firmware(id: $id)
    }
`;

interface Props {
    firmware: string | undefined,
    latestFirmware: string | undefined,
    id: string,
    changelog: string
}

export const FirmwareUpdate: FC<Props> = ({firmware, latestFirmware, id, changelog}): ReactElement => {
    const { t } = useTranslation();
    const [update, {loading}] = useMutation(UPDATE_FIRMWARE);
    const [showNotification, setShowNotification] = useState(false);
    const [showModal, setShowModal] = useState(false);

    return (
        <Box>
            {showModal && <Modal
                title={t('update_confirm')}
                message={t('update_warning')}
                onClose={() => {setShowModal(false)}}
                onAction={async () => {
                    setShowModal(false);
                    await update({variables: {id}});
                    setShowNotification(true);
                }}
                buttonProps={{color: 'status-warning', label: t('Update')}}
            />}
            {showNotification && <Notification
                toast
                status='normal'
                title={t('update_started')}
                message={t('update_wait')}
                onClose={() => setShowNotification(false)}
            />}
            <Heading level={3} fill>{t('Device Firmware')}</Heading>
            <Box>{firmware && (firmware === latestFirmware ?
                    <Box direction='row' gap='small'>
                        <Checkmark color='status-ok'/>
                        <Text weight='bold'>{t('device_updated')}</Text>
                    </Box> :
                    <Box direction='row' gap='small'>
                        <StatusWarning color='status-warning'/>
                        <Text weight='bold'>{t('device_not_updated')}</Text>
                    </Box>

            )}</Box>
            <Text margin={{top: 'small'}}>{t('Current version')}: <strong>{firmware || 'N/A'}</strong></Text>
            <Text>{t('available_version')} <strong>{latestFirmware}</strong></Text>
            {(firmware && firmware !== latestFirmware) && <React.Fragment>
                {changelog && <Box margin={{top: 'small'}}>
                    <Text weight='bold'>{t('Changelog')}</Text>
                    <Paragraph size='small'>{changelog}</Paragraph>
                </Box>}
                <Button
                    alignSelf='start'
                    size='large'
                    primary
                    margin={{top: 'small'}}
                    color='status-warning'
                    icon={loading ? <Spinner/> : undefined}
                    label={t('Update')}
                    onClick={() => setShowModal(true)}
                />
            </React.Fragment>}
        </Box>
    );
};