import moment from "moment-timezone";

export const round = (number: number | undefined): string => number?.toFixed(2) || "0";

export const isOnline = (time: string): boolean => {
    if (!time) return false;
    const lastUpdate = moment.utc(time).tz('Europe/Sofia').format();
    const duration = moment.duration(moment().diff(lastUpdate));
    return duration.asMinutes() <= 15;
}

export const setHour = (dateISO: string, hour: number): string => {
    const date = moment(dateISO).tz('Europe/Sofia');
    date.set({hours: hour, minutes: 0, seconds: 0});
    return date.utc().format();
}

export const getDate = (timestamp?: string): string => moment(timestamp).toISOString().split('T')[0]
