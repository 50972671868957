import React, {FC, ReactElement, ComponentType} from 'react';
import {Redirect, Route, Switch, RouteProps} from "react-router-dom";
import {RouteComponentProps} from "react-router";
import {SignInPage} from "./pages/Auth/SignInPage";
import {DashboardPage} from "./pages/DashboardPage";
import {SignUpPage} from "./pages/Auth/SignUpPage";
import {RequestPasswordResetPage} from "./pages/Auth/RequestPasswordResetPage";
import {isLoggedInVar} from "./services/graphql/cache";
import {SuccessfulSignUpPage} from "./pages/Auth/SuccessfulSignUpPage";
import {PlacesPage} from "./pages/Place/PlacesPage";
import {DevicesPage} from "./pages/DevicesPage";
import {DevicePage} from "./pages/Device/DevicePage";
import {ProfilePage} from "./pages/ProfilePage";
import {CreatePlacePage} from "./pages/CreatePlacePage";
import {EditDevicePage} from "./pages/Device/EditDevicePage";
import {VerifyProfilePage} from "./pages/Auth/VerifyProfilePage";
import {PasswordResetPage} from "./pages/Auth/PasswordResetPage";
import {SupportPage} from "./pages/SupportPage";
import {DemoPage} from "./pages/DemoPage";
import {NoMatchPage} from "./pages/NoMatchPage";
import {PlacePage} from "./pages/Place/PlacePage";

interface RP extends RouteProps {
    component: ComponentType<RouteComponentProps<any>>
}

const AuthRoute: FC<RP> = ({component: Component, ...rest}): ReactElement =>
    <Route
        {...rest}
        render={props => {
            // @ts-ignore
            let {from} = props.location.state || {from: {pathname: "/"}};
            return (
                isLoggedInVar() ? (
                    <Redirect
                        to={from}
                    />
                ) : (
                    <Component {...props} />
                ))
        }}
    />;

const PrivateRoute: FC<RP> = ({component: Component, ...rest}): ReactElement =>
    <Route
        {...rest}
        render={props =>
            isLoggedInVar() ?
                <Component {...props} /> :
                <Redirect to={{
                    pathname: "/sign-in",
                    state: {from: props.location}
                }}/>
        }
    />;


const routes = (
    <Switch>
        <PrivateRoute exact path="/" component={DashboardPage}/>
        <PrivateRoute exact path="/devices" component={DevicesPage}/>
        <PrivateRoute exact path="/devices/:id" component={DevicePage}/>
        <PrivateRoute exact path="/devices/:id/edit" component={EditDevicePage}/>
        <PrivateRoute exact path="/profile" component={ProfilePage}/>
        <PrivateRoute exact path="/places/create" component={CreatePlacePage}/>
        <PrivateRoute exact path="/places" component={PlacesPage}/>
        <PrivateRoute exact path="/places/:id" component={PlacePage}/>
        <PrivateRoute exact path="/support" component={SupportPage}/>
        <AuthRoute path="/sign-in" component={SignInPage}/>
        <AuthRoute path="/demo" component={DemoPage}/>
        <AuthRoute path="/request-password-reset" component={RequestPasswordResetPage}/>
        <AuthRoute path="/reset-password/:token" component={PasswordResetPage}/>
        <AuthRoute path="/sign-up" component={SignUpPage}/>
        <AuthRoute path="/successful-sign-up" component={SuccessfulSignUpPage}/>
        <AuthRoute path="/verify-profile/:token" component={VerifyProfilePage}/>
        <PrivateRoute path="*" component={NoMatchPage}/>
    </Switch>
);

export default routes;