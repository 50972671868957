import React, {FC, ReactElement} from "react";
import {Wifi, WifiLow, WifiMedium, WifiNone} from "grommet-icons";

interface Props {
    strength: number,
    size?: 'small' | 'medium' | 'large' | 'xlarge'
}

export const SignalStrengthMeter: FC<Props> = ({strength, size = 'medium'}): ReactElement => (
    strength < 25 ?
        <WifiNone size={size} color='status-error'/> :
        strength < 50 ?
            <WifiNone size={size} color='status-warning'/> :
            strength < 75 ?
                <WifiMedium size={size} color='status-ok'/> :
                <Wifi size={size} color='status-ok'/>
);