import React, {FC, ReactElement} from 'react';
import {Box, BoxProps, Table, TableBody, TableCell, TableHeader, TableRow, Text} from 'grommet';
import { Tile } from '../Tile';
import {round} from "../../services/helpers";
import {useTranslation} from "react-i18next";

interface Props extends BoxProps {
   data: {
       u_a: number,
       u_b: number,
       u_c: number,
       i_a: number,
       i_b: number,
       i_c: number,
       freq: number
   }
}

export const OutputParameters: FC<Props> = ({data: {u_a,u_b,u_c, i_a,i_b,i_c, freq}, ...rest}): ReactElement => {
    const { t } = useTranslation();
    return (
        <Tile title={t('Output Parameters')} {...rest}>
            <Box pad='small'><Text>{t('Frequency')}: {round(freq)} Hz</Text></Box>
            <Table margin={{top: 'small'}}>
                <TableHeader>
                    <TableRow>
                        <TableCell scope="col" border="bottom">
                            <strong>{t('Phase')} A</strong>
                        </TableCell>
                        <TableCell scope="col" border="bottom">
                            <strong>{t('Phase')} B</strong>
                        </TableCell>
                        <TableCell scope="col" border="bottom">
                            <strong>{t('Phase')} C</strong>
                        </TableCell>
                    </TableRow>
                </TableHeader>
                <TableBody>
                    <TableRow>
                        <TableCell scope="row">
                            {round(u_a)} V
                        </TableCell>
                        <TableCell scope="row">
                            {round(u_b)} V
                        </TableCell>
                        <TableCell scope="row">
                            {round(u_c)} V
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>{round(i_a)} A</TableCell>
                        <TableCell>{round(i_b)} A</TableCell>
                        <TableCell>{round(i_c)} A</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </Tile>
    );
};