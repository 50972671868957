import React, {FC, ReactElement, useMemo, useState} from 'react';
import {AggregatedStatsTile} from "../place/AggregatedStatsTile";
import {useTranslation} from "react-i18next";

interface Props {
    powerToday: number,
    powerMonth: number,
}

export const CO2Widget: FC<Props> = ({powerMonth, powerToday, ...rest}): ReactElement => {
    const {t} = useTranslation();
    const [option, setOption] = useState(powerToday);
    const scaled = useMemo(() => {
        const value = option * 0.804;
        return {
            value: value > 1000 ? value / 1000 : value,
            unit: value > 1000 ? 't' : 'kg'
        }
    }, [option]);
    return (
        <AggregatedStatsTile
            {...rest}
            value={`${option ? (scaled.value).toFixed(2) : 'N/A'} ${scaled.unit}`}
            options={[
                {plain: true, label: t('Today'), color: option === powerToday ? 'accent-4' : undefined, onClick: () => setOption(powerToday)},
                {plain: true, label: t('Last Month'), color: option === powerMonth ? 'accent-4' : undefined, onClick: () => setOption(powerMonth)}
            ]}
            title={`CO2 ${t("Saved")}`}
        />
    );
};