import React, {FC, ReactElement, useState} from "react";
import {Notification} from "../Notification";
import {useTranslation} from "react-i18next";

interface Props {
    show: boolean
}

export const NotConfiguredNotification: FC<Props> = ({show}): ReactElement | null => {
    const { t } = useTranslation();
    const [visible, setVisible] = useState<boolean>(show);
    if (!visible) {
        return null;
    }

    return (
        <Notification onClose={() => setVisible(false)} title={t('device_not_configured')} message={t('device_configure_hint')}/>
        // <Notification
        //     toast
        //     status='warning'
        //     title='Device is not configured yet!'
        //     message='You need to define what is connected to the device by clicking on the wrench on the right.'
        //     // @ts-ignore
        //     onClose{() => setVisible(false)}
        // />
    )
};