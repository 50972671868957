import {gql, InMemoryCache, makeVar} from '@apollo/client';
import {AUTH_TOKEN_KEY, loadState, REFRESH_TOKEN} from "../../localStorage";
import jwtDecode from "jwt-decode";

export const cache: InMemoryCache = new InMemoryCache({
    typePolicies: {
        Query: {
            fields: {
                userId: {
                    read() {
                        return getClaimField('x-hasura-user-id')
                    }
                },
                userEmail: {
                    read() {
                        return getField('name')
                    }
                },
                isLoggedIn: {
                    read() {
                        return isLoggedInVar();
                    }
                },
            }
        }
    }
});

export const typeDefs = gql`
    extend type Query {
        isLoggedIn: Boolean!
    }
`;

export const isLoggedInVar =
    makeVar<boolean>(!!loadState(REFRESH_TOKEN)); // TODO: Check expirity of JWT token as well

export const getClaimField = (field: string, jwt?: any) => {
    const token = jwt || loadState(AUTH_TOKEN_KEY);
    if (!token) {
        return null
    }
    const data: any = jwtDecode(token);
    return data['https://hasura.io/jwt/claims'][field]
};

export const getField = (field: string, jwt?: any) => {
    const token = jwt || loadState(AUTH_TOKEN_KEY);
    if (!token) {
        return null
    }
    const data: any = jwtDecode(token);
    return data[field];
};