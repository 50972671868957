import React, {FC, ReactElement, useMemo} from 'react';
import {Box, Stack, Text} from "grommet";
import {Alert, Launch} from "grommet-icons";
import {ConnectionStatus} from "../device/ConnectionStatus";
import {DeviceStatus} from "../device/DeviceStatus";
import {isOnline} from "../../services/helpers";
import {SignalStrengthMeter} from "../device/SignalStrengthMeter";


export const DeviceCard: FC<any> = ({model, id, last_active, onClick, connection_active, status, signal_strength, boxProps, ...rest}): ReactElement => {
    const online = useMemo(() => isOnline(last_active[0]?.time), [last_active]);
    return (
        <Box elevation='medium' round='small' {...boxProps}>
            <Stack anchor='bottom-left'>
                <Box pad='small' direction='row' align='center' gap='small' background='neutral-2' round={{corner: 'top', size: 'small'}}>
                    <Launch size='large' color='accent-4'/>
                    <Box>
                        <Text>{`${model?.manufacturer?.name} ${model?.name}`}</Text>
                        <Text size='small'>
                            {`${model?.model_type?.name}`} <strong>{`${model?.rated_power}kW`}</strong>
                        </Text>
                        <Text size='small'>{id}</Text>
                        {online ? <DeviceStatus activeConnection={connection_active} status={status} size='small'/> :
                        <Text size='small' weight='bold'>Status Unknown</Text>}
                    </Box>
                </Box>
                {online &&
                    <Box pad={{left: 'xsmall', bottom: 'xsmall'}}>
                        <SignalStrengthMeter size='medium' strength={signal_strength}/>
                    </Box>
                }
            </Stack>
            <Box direction='row'
                 background='light-2'
                 pad={{horizontal: 'small', vertical: 'xsmall'}}
                 round={{corner: 'bottom', size: 'small'}}
                 justify='between'
            >
                <ConnectionStatus time={last_active[0]?.time} />
                <Box direction='row'  align='center'>
                    <Text weight='bold'>0</Text>
                    <Alert color='status-warning'/>
                </Box>
            </Box>
        </Box>
    );
};