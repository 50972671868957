import React, {FC, ReactElement, useMemo} from "react";
import {ActivePowerItem, ActivePowerWidget} from "../widgets/ActivePowerWidget";
import {gql, useSubscription} from "@apollo/client";
import moment from "moment-timezone";
import {getDate} from "../../services/helpers";

const FETCH_ACTIVE_POWER = gql`
    subscription ($place_id: Int! $date: timestamp) {
        devices: device(where: {place_id: {_eq: $place_id}}) {
            id
            model_id
            model {
                id
                name 
                manufacturer {
                    id
                    name
                }
            }
            device_metrics(where: {time: {_gt: $date}} order_by: {time: desc} limit: 1) {
                time
                p_active
            }
        }
    }
`;

export const ActivePower: FC<any> = ({id, ...rest}): ReactElement => {
    const date = getDate();
    const {data, loading, error} = useSubscription(FETCH_ACTIVE_POWER, {variables: {place_id: id, date}});
    const power = useMemo(() => data?.devices?.reduce((acc:any, current:any) => acc + current?.device_metrics[0]?.p_active || 0, 0), [data]);
    const items = useMemo(() => data?.devices?.map((device: any, index: number): ActivePowerItem => ({
        label: device?.id,
        value: device?.device_metrics[0]?.p_active / power * 100,
        power: device?.device_metrics[0]?.p_active,
        tip: device?.model_id ? `${device.model?.manufacturer?.name} ${device.model?.name}` : undefined,
    })), [data, power]);

    return (
        <ActivePowerWidget loading={loading} power={power} values={items} {...rest}/>
    )
}