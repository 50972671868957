import React, {FC, ReactElement, useState} from 'react';
import {Box, Button, Heading, Paragraph, Spinner, Notification} from "grommet";
import {gql, useMutation} from "@apollo/client";
import {Modal} from "../Modal";
import {useTranslation} from "react-i18next";

const RESTART_DEVICE = gql`
    mutation ($id: String!) {
        restart_device(id: $id)
    }
`;

interface Props {
    id: string
}

export const RestartDevice: FC<Props> = ({id}): ReactElement => {
    const { t } = useTranslation();
    const [restart, {loading}] = useMutation(RESTART_DEVICE);
    const [showNotification, setShowNotification] = useState(false);
    const [showModal, setShowModal] = useState(false);

    return (
        <Box>
            {showModal && <Modal
                title={t('restart_confirm')}
                message={t('restart_note')}
                onClose={() => {setShowModal(false)}}
                onAction={async () => {
                    setShowModal(false);
                    await restart({variables: {id}});
                    setShowNotification(true);
                }}
                buttonProps={{color: 'status-error', label: t('Restart')}}
            />}
            {showNotification && <Notification
                toast
                status='normal'
                title={t('restart_initiated')}
                message={t('restart_wait')}
                onClose={() => setShowNotification(false)}
            />}
            <Heading level={3} fill>{t('Restart Device')}</Heading>
            <Paragraph>{t('restart_if_troubles')}</Paragraph>
            <Button
                size='large'
                primary
                color='status-error'
                icon={loading ? <Spinner/> : undefined}
                label={t('Restart')}
                alignSelf='start'
                onClick={() => setShowModal(true)}
            />
        </Box>
    );
};