import React, {FC, ReactElement, useContext} from 'react';
import {
    Box,
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Grid,
    Heading,
    ResponsiveContext,
    Image,
    Text, Stack
} from "grommet";
import {gql, useQuery} from "@apollo/client";
import {PageHeader} from "../../components/PageHeader";
import {useHistory} from "react-router-dom";
import {SpinnerContainer} from "../../components/SpinnerContainer";
import {
    Money,
    Trigger,
    Calendar,
    Grow,
    Deploy,
    Alert,
    Checkmark
} from "grommet-icons";
import {getDate, isOnline} from "../../services/helpers";
import {useTranslation} from "react-i18next";

type Props = {
}

const FETCH_PLACES = gql`
    query ($date: timestamp!){
        places: place {
            id
            name
            price_per_kwh
            devices {
                id
                model {
                    id
                    rated_power
                }
                device_metrics(where: {time: {_gt: $date}} order_by: {time: desc} limit: 1) {
                    time
                    p_daily
                }
            }
        }
    }
`;

export const PlacesPage: FC<Props> = (): ReactElement => {
    const { t } = useTranslation();
    const size = useContext(ResponsiveContext);
    const date = getDate();
    const {data, loading, error} = useQuery(FETCH_PLACES, {variables: {date}});
    const history = useHistory();
    if (loading) {
        return <SpinnerContainer/>
    }

    return (
        <Box flex={false} pad="medium">
            <PageHeader
                name={t('Places')}
                action={<Button primary label={t("Create")} onClick={() => history.push('/places/create')}/>}
            />
            <Grid columns={size !== 'small' ? 'medium' : '100%'} gap="medium">
                {data?.places.length > 0 ? data.places.map((card: any) => {
                    const ratedPower = card?.devices?.reduce((acc:any , current: any) => acc + current?.model?.rated_power, 0);
                    const powerGenerated = card?.devices?.reduce((acc:any , current: any) => acc + current?.device_metrics[0]?.p_daily || 0, 0);
                    const devicesOnline = card?.devices?.reduce((acc:any, current: any) => acc + isOnline(current?.device_metrics[0]?.time), 0);
                    return (
                    <Card key={card.id} elevation="large" onClick={() => history.push(`/places/${card.id}`)}>

                            <CardBody height="small">
                                <Image
                                    fit="cover"
                                    src="//news.mit.edu/sites/default/files/styles/news_article__image_gallery/public/images/202006/MIT-PPA-1-Press_solar_array_3.jpg?itok=42Lhjby-"
                                    a11yTitle="PV Powerplant"
                                />
                            </CardBody>
                            <Box pad={{ horizontal: 'medium', bottom: 'medium' }} responsive={false}>
                                <Heading level="3" margin={{ vertical: 'medium' }}>
                                    {card.name}
                                </Heading>
                                <Box direction='row-responsive' gap='small'>
                                    <Box gap='medium'>
                                        <Box direction='row' gap='small'>
                                            <Trigger size='large' color='status-warning'/>
                                            <Box>
                                                <Text weight='bold'>{ratedPower.toFixed(2)} kWp</Text>
                                                <Text>{t("Installed Power")}</Text>
                                            </Box>
                                        </Box>
                                        <Box direction='row' gap='small'>
                                            <Stack anchor='bottom-right'>
                                                <Calendar size='large'/>
                                                <Trigger size='32px' color='status-warning'/>
                                            </Stack>
                                            <Box>
                                                <Text weight='bold'>{powerGenerated ? powerGenerated.toFixed(2) : 'N/A'} kW</Text>
                                                <Text>{t("Power Generated Today")}</Text>
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Box gap='medium'>
                                        <Box direction='row' gap='small'>
                                            <Money size='large' color='status-ok'/>
                                            <Box>
                                                <Text weight='bold'>{powerGenerated ? (powerGenerated * card.price_per_kwh).toFixed(2) : 'N/A'} {t('leva')}</Text>
                                                <Text>{t("Revenue")}</Text>
                                            </Box>
                                        </Box>
                                        <Box direction='row' gap='small'>
                                            <Grow size='large' color='neutral-4'/>
                                            <Box>
                                                <Text weight='bold'>{powerGenerated ? (powerGenerated * 0.804).toFixed(2) : 'N/A'} kg</Text>
                                                <Text>CO<sub>2</sub> {t("Saved")}</Text>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                                <Box direction='row' margin={{top: 'medium'}} justify='between'>
                                    <Box direction='row' gap='small' align='center'>
                                        <Stack anchor='bottom-right'>
                                            <Deploy size='32px'/>
                                            <Box
                                                background={devicesOnline === card?.devices?.length ? 'status-ok' : 'status-error'}
                                                height='15px'
                                                width='15px'
                                                pad={{ horizontal: 'xsmall' }}
                                                round
                                            />
                                        </Stack>
                                        <Text weight='bold'>{devicesOnline}/{card?.devices?.length} {t("Devices Online")}</Text>
                                    </Box>
                                    <Box direction='row' gap='small' align='center'>
                                        {/* TODO: Once we have alerts implemented, show them here as well */}
                                        <Text weight='bold' size='large'>{t("No alerts")}</Text>
                                        <Checkmark size='32px' color='status-ok'/>
                                        {/*<Alert size='large' color='status-warning'/>*/}
                                    </Box>
                                </Box>
                            </Box>
                            {/*<CardFooter>*/}
                            {/*    <Box direction="row" align="center" gap="small">*/}
                            {/*    </Box>*/}
                            {/*</CardFooter>*/}
                    </Card>
                )}) : <Text>{t("no_places")}</Text>}
            </Grid>
        </Box>
    );
};