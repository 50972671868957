import React, {FC, ReactElement, useCallback, useRef, useState} from "react";
import {Box, TextInput, Text} from "grommet";
import {Alert, Search} from "grommet-icons";
import {locationSearch, NominatimPlace} from "../../services/nominatim";
import {debounce} from "lodash";
import {useTranslation} from "react-i18next";

const formatSuggestions = (suggestions: object[]) =>
    suggestions
        .map((suggestion: any, index, list) => ({
            label: (
                <Box
                    direction="row"
                    align="center"
                    gap="small"
                    border={index < list.length - 1 ? 'bottom' : undefined}
                    pad="small"
                >
                    <Text>
                        <strong>{suggestion.display_name}</strong>
                    </Text>
                </Box>
            ),
            value: suggestion.display_name,
            data: suggestion
        }));

interface Props {
    error?: string,
    onSelect: (arg: NominatimPlace) => void
}

export const SearchLocationInput: FC<Props> = ({error, onSelect = (suggestion: NominatimPlace) => {}}): ReactElement => {
    const { t } = useTranslation();
    const [value, setValue] = useState('');
    const [suggestionOpen, setSuggestionOpen] = useState(false);
    const [suggestions, setSuggestions] = useState([]);
    const boxRef = useRef();

    const debounceOnChange = useCallback(debounce(async (query: string) => {
        // @ts-ignore
        setSuggestions(formatSuggestions(await locationSearch(query.trim())));
    }, 400), []);

    const onChange = useCallback(async (event) => {
        const {value: newValue} = event.target;
        setValue(newValue);

        if (!newValue.trim()) {
            setSuggestions([]);
            debounceOnChange.cancel();
        } else {
            debounceOnChange(newValue);
            // @ts-ignore
            // setSuggestions(formatSuggestions(await locationSearch(newValue.trim())));
        }
    }, [debounceOnChange]);

    const onSuggestionSelect = useCallback(
        (event) => {
            setValue(event.suggestion.value);
            onSelect(event.suggestion.data);
            },
        [onSelect],
    );

    const onSuggestionsOpen = useCallback(() => {
        // @ts-ignore
        // setSuggestions(formatSuggestions(folks, value));
        setSuggestionOpen(true);
    }, []);

    const onSuggestionsClose = useCallback(() => {
        setSuggestions([]);
        setSuggestionOpen(false);
    }, []);

    return (
        <Box gap='small'>
        <Box
            // @ts-ignore
            ref={boxRef}
            fill='horizontal'
            direction="row"
            align="center"
            pad={{horizontal: 'small', vertical: 'xsmall'}}
            round="small"
            elevation={suggestionOpen ? 'medium' : undefined}
            border={{
                side: 'all',
                color: error ? 'status-error' : suggestionOpen ? 'transparent' : 'border',
            }}
            style={
                suggestionOpen
                    ? {
                        borderBottomLeftRadius: '0px',
                        borderBottomRightRadius: '0px',
                    }
                    : undefined
            }
        >
            <Search color="brand"/>
            <TextInput
                // @ts-ignore
                dropTarget={boxRef.current}
                placeholder={t('search_location')}
                plain
                suggestions={suggestions}
                value={value}
                onChange={onChange}
                onSuggestionsOpen={onSuggestionsOpen}
                onSuggestionsClose={onSuggestionsClose}
                onSuggestionSelect={onSuggestionSelect}
            />
        </Box>
            {error && <Text color='status-error'>{error}</Text>}
            <Box margin={{left: 'small'}} direction='row' gap='small'><Alert color='status-warning'/><Text> {t('location_used_for_forecasts')}</Text></Box>
        </Box>
    );
};