import React, {FC, ReactElement, useMemo} from "react";
import {ActivePowerItem, ActivePowerWidget} from "../widgets/ActivePowerWidget";

export const ActivePower: FC<any> = ({data, ...rest}): ReactElement => {
    const pvs = useMemo(() => [...Array(8)].map((_, i): ActivePowerItem => ({
        label: `PV${i+1}`,
        value: data[`pv${i+1}_u`] * data[`pv${i+1}_i`] / 1000 / data.p_active * 100,
        power: data[`pv${i+1}_u`] * data[`pv${i+1}_i`] / 1000,
    })), [data]);

    return (
        <ActivePowerWidget power={data.p_active} values={pvs} {...rest}/>
    )
}