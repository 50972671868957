import {deepMerge} from "grommet/utils";
import {grommet, ThemeType} from "grommet";


const theme: ThemeType = deepMerge(grommet, {
    button: {
        badge: {
            container: {
                background: 'accent-1'
            }
        }
    },
    textInput: {
        disabled: {
            opacity: 0.9
        }
    }
});

export default theme;